import {createSlice} from '@reduxjs/toolkit';
import {ReportStore} from './types';


const initialState: ReportStore = {
    filterHolding: [],
    date: [],
    FObjects: [],
    group: 'D',
    sortType: 0,
    reportSwitchIndex: 0
};

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setReportFilter: (state, {payload}) => {
            state.filterHolding = payload;
        },
        setDateFilter: (state, {payload}) => {
            state.date = payload;
        },
        setGroup: (state, {payload}) => {
            state.group = payload;
        },
        setSortType: (state, {payload}) => {
            state.sortType = payload;
        },
        setChangeReportIndex: (state, {payload}) => {
            state.reportSwitchIndex = payload;
        },
        setSelectObj: (state, {payload}) => {
            state.FObjects = payload;
        },
        clearReportFilter: (state) => {
            state.filterHolding = [];
            state.date = [];
        }
    },
});

export const {
    setReportFilter,
    setDateFilter,
    clearReportFilter,
    setGroup,
    setSortType,
    setChangeReportIndex,
    setSelectObj
} = reportSlice.actions;
export default reportSlice.reducer;
