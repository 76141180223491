import {Action, configureStore, Reducer} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {api, apiPush} from './api';
import {setupListeners} from '@reduxjs/toolkit/query';
import {AuthSlice, logout} from '../screens/auth/store/auth.slice';
import {rootReducer} from './rootReducer';
import {AppState} from './app.slice';
import {QuizState} from '../screens/quiz/store/quiz.slice';
import {CameraState} from '../screens/camera/store/types';
import {TaskMenuState} from '../screens/taskTypes/store/types';
import {FilterSlice} from '../components/taskFilter/store/type';
import {FinancialFilterSlice} from '../components/financialFilter/store/type';
import {FinancialMenuState} from '../screens/financial/state/types';
import {ChatState} from '../components/chat/store/types';
import {ReportStore} from '../screens/report/store/types';
import {TaskSwitcherState} from '../screens/taskCreate/store/types';
import {InventoryStore} from '../screens/inventory/store/types';
import {ActionsState} from '../screens/actionList/store/types';
import {IPhotoReportState} from '../screens/photoReport/store/types';
import {EmployeeState} from '../screens/selectEmployee/store/types';
import {CallState} from '../screens/phoneCall/store/types';
import {TableState} from '../screens/tableView/store/types';
import {MonitoringStore} from '../screens/monitoring/store/types';
import {OrderStore} from '../screens/monitoringOrder/store/types';
import {TimeControlState} from '../screens/timeControl/store/types';
import {ReviewState} from '../screens/reviews/store/types';

const persistConfig = {
    key: 'ifood',
    storage,
    blacklist: ['chat']
};

interface StateReducer {
    app: AppState;
    auth: AuthSlice;
    quiz: QuizState;
    camera: CameraState;
    taskMenu: TaskMenuState;
    financialMenu: FinancialMenuState;
    filter: FilterSlice;
    financialFilter: FinancialFilterSlice;
    chat: ChatState;
    report: ReportStore;
    taskSwitch: TaskSwitcherState;
    inventory: InventoryStore;
    actions: ActionsState;
    photoReport: IPhotoReportState;
    employee: EmployeeState;
    call: CallState;
    table: TableState;
    monitor: MonitoringStore;
    order: OrderStore;
    timeControl: TimeControlState;
    reviews: ReviewState;
}

const appReducer: Reducer<StateReducer | undefined, Action> = (state: any, action) => {

    if (action.type === logout.type) {
        state = undefined;
    }
    return rootReducer(state, action);
};


const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    }).concat(api.middleware as any, apiPush.middleware as any),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
