import React from 'react';
import {MainLayout, QuestionVariable} from '../../layouts';
import Header from '../../components/header';
import {Location, useLocation, useNavigate, useParams} from 'react-router-dom';
import {BackButton} from '../../components/backButton';
import Footer from '../../components/footer';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {ListMenu} from '../home/store/types';

export const ChecksScreen = () => {
    const navigate = useNavigate();
    const {idForm} = useParams<{ idForm: string }>();
    const location: Location = useLocation();
    const {state} = location as Location & { state: { title: string } };
    const {data} = useGetAllQuestionsQuery();
    const viewButtons = data?.ListMenu.find(item => item.idForm === Number(idForm))?.children as ListMenu[] ?? [];


    const handlePress = (item: ListMenu) => {
        navigate(`/check-type/${item.idForm}`);
    };
    return (
        <MainLayout>
            <Header breadCrumbs={state?.title ? state.title : 'КРО проверки'}/>
            <BackButton text={'Назад в главное меню'} onClick={() => navigate(-1)}/>
            <QuestionVariable renderData={viewButtons} handlePress={handlePress}/>
            <Footer/>
        </MainLayout>
    );
};
