import {api} from '../../../store/api';
import {CheckByCamera, HashCred, SaveCred, Shift} from './types';


export const apiTimeControl = api.injectEndpoints({
    endpoints: (build) => ({
        getCredential: build.query<HashCred, void>({
            query: () => ({
                url: '/admin/getHash.php',
                method: 'GET'
            })
        }),
        saveCredential: build.mutation<any, { body: SaveCred }>({
            query: ({body}) => ({
                url: '/admin/userAddHash.php',
                method: 'POST',
                body: body
            })
        }),
        checkByCamera: build.mutation<CheckByCamera, { checkType: number; FObjects: number }>({
            query: ({checkType, FObjects}) => ({
                url: '/attendance/checkCamera.php',
                method: 'POST',
                body: {checkType, FObjects}
            })
        }),
        checkByQr: build.mutation<{ status: boolean; message?: string }, { checkType: number; FObjects: number, qrContent: string }>({
            query: ({checkType, FObjects, qrContent}) => ({
                url: '/attendance/checkQR.php',
                method: 'POST',
                body: {checkType, FObjects, qrContent}
            })
        }),
        getWorkShift: build.query<Shift[], void>({
            query: () => ({
                url: '/attendance/onShift.php',
                method: 'GET'
            }),
            transformResponse: ({listOnShift}) => {
                return listOnShift;
            }
        }),
        registrationInNewObj: build.query<{ status: boolean; message?: string }, { ID_Obj: string }>({
            query: ({ID_Obj}) => ({
                url: `/attendance/registrationAtAnotherRestaurant.php?ID_Obj=${ID_Obj}`,
                method: 'GET'
            })
        })
    }), overrideExisting: true
});

export const {
    useSaveCredentialMutation,
    useLazyGetCredentialQuery,
    useCheckByCameraMutation,
    useCheckByQrMutation,
    useGetWorkShiftQuery,
    useLazyRegistrationInNewObjQuery
} = apiTimeControl;
