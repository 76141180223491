import React from 'react';
import styles from './style.module.scss';
import {ItemRating} from './ItemRating';
import {ReviewBody} from '../../store/types';
import {useCollapse} from 'react-collapsed';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../../hooks/app.hooks';
import {setReviewsFilter} from '../../store/review.slice';
import {RATING} from '../../ReviewCommon';

interface TableRowProps<T> {
    row: T;
    col: number;
}

export const TableRow = <T extends ReviewBody, >(
    {
        row,
        col,
    }: TableRowProps<T>
) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {getCollapseProps, setExpanded, isExpanded} = useCollapse();

    const {filter} = useAppSelector(state => state?.reviews);

    const handlePress = (FRatings: string) => {

        if (RATING.includes(FRatings)) {
            const newKeys = [
                ...filter,
                {type: 'FRatings', value: FRatings},
                {type: 'tag' in row ? 'tag' : 'provider', value: 'tag' in row ? row.tag : row.name}
            ];

            dispatch(setReviewsFilter(newKeys));
            navigate('/review-objects');
        } else {
            const newKeys = [
                ...filter,
                {type: 'tag' in row ? 'tag' : 'provider', value: 'tag' in row ? row.tag : row.name}
            ];

            dispatch(setReviewsFilter(newKeys));
            navigate('/review-objects');
        }
    };
    return (
        <>
            <div className={styles.tableRowCollapse}>
                <ItemRating
                    num={row.name}
                    col={col}
                    display={row.all.display}
                    percent={'100'}
                    isInner={false}
                    mode={'dropdown'}
                    isExpanded={isExpanded}
                    setExpanded={setExpanded}
                    handleSelect={() => {
                        handlePress('all');
                    }}
                />
                <div className={styles.tableRowBody} {...getCollapseProps()}>
                    <ItemRating
                        num={'1*'}
                        col={col}
                        isInner={true}
                        display={row.rating_1.display}
                        percent={row.rating_1Percent.display}
                        handleSelect={() => handlePress('1')}/>
                    <ItemRating
                        num={'2*'}
                        col={col}
                        isInner={true}
                        display={row.rating_2.display}
                        percent={row.rating_2Percent.display}
                        handleSelect={() => handlePress('2')}/>
                    <ItemRating
                        num={'3*'}
                        col={col}
                        isInner={true}
                        display={row.rating_3.display}
                        percent={row.rating_3Percent.display}
                        handleSelect={() => handlePress('3')}/>
                    <ItemRating
                        num={'4*'}
                        col={col}
                        isInner={true}
                        display={row.rating_4.display}
                        percent={row.rating_4Percent.display}
                        handleSelect={() => handlePress('4')}/>
                    <ItemRating
                        num={'5*'}
                        col={col}
                        isInner={true}
                        display={row.rating_5.display}
                        percent={row.rating_5Percent.display}
                        handleSelect={() => handlePress('5')}/>
                </div>
            </div>

        </>
    );
};
