import React from 'react';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import {OrderStatusesType} from '../../monitoringOrder/store/types';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import {setFilter, setStatuses} from '../store/monitoring.slice';

const switcher: { value: OrderStatusesType, label: string }[] = [
    {label: 'ожидание', value: 'Waiting'},
    {label: 'ожид готовки', value: 'WaitCooking'},
    {label: 'готовка', value: 'CookingStarted'},
    {label: 'на полке', value: 'CookingCompleted'},
    {label: 'в пути', value: 'OnWay'},
    {label: 'доставлено', value: 'Delivered'},
    {label: 'закрыто', value: 'Closed'},
    {label: 'отмена', value: 'Cancelled'},
];

export const SwitchStatus = ({position}: { position: 'fixed' | 'initial' }) => {
    const dispatch = useAppDispatch();
    const {pointsOrders, statuses, filter} = useAppSelector(state => state.monitor);

    const switchStatus = (status: OrderStatusesType) => {
        const existingFilter = filter.find((f: any) => f.type === 'FStatuses');

        if (statuses.includes(status)) {
            const newArr = statuses.filter(i => i !== status);
            dispatch(setStatuses(newArr));

            // Обновляем значение фильтра, если уже существует 'FStatuses'
            if (existingFilter) {
                const updatedFilterValue = newArr.length > 0 ? newArr.join(',') : '';
                const updatedFilter = filter.map((f: any) => f.type === 'FStatuses' ? {
                    ...f,
                    value: updatedFilterValue
                } : f);
                dispatch(setFilter(updatedFilter));
            } else {
                dispatch(setFilter([...filter, {type: 'FStatuses', value: newArr.length > 0 ? newArr.join(',') : ''}]));
            }
        } else {
            const updatedStatuses = statuses ? [...statuses, status] : [status];
            dispatch(setStatuses(updatedStatuses));

            // Обновляем или добавляем фильтр 'FStatuses'
            if (existingFilter) {
                const updatedFilterValue = [...statuses, status].join(',');
                const updatedFilter = filter.map((f: any) => f.type === 'FStatuses' ? {
                    ...f,
                    value: updatedFilterValue
                } : f);
                dispatch(setFilter(updatedFilter));
            } else {
                dispatch(setFilter([...filter, {type: 'FStatuses', value: status}]));
            }
        }
    };

    return (
        <div className={styles.switcher} style={{position}}>
            {switcher.map(item =>
                <button
                    key={item.value}
                    className={
                        statuses.includes(item.value)
                            ? classNames(styles.switcherButton, styles.switcherButtonActive)
                            : styles.switcherButton
                    }
                    //disabled={pointsOrders?.length === 0}
                    onClick={() => switchStatus(item.value)}
                >{item.label}</button>)}
        </div>
    );
};
