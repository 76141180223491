import React from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';
import {IPermission} from '../../store/types';

interface SwitcherTabProps {
    buttons: string[];
    onClick: (index: number) => void;
    isActive?: number | number[];
    isMultiple?: boolean;
    skip?: IPermission;
    pageType: PageType;
    isDisabled?: boolean;
    simpleSkipArrayIndex?: number[];
}


type PageType = 'task' | 'financial' | 'info' | 'profile' | 'report'

function isSkipIndex(permission: IPermission, index: number, pageType: PageType): boolean {
    if (permission === undefined) return false;

    if (pageType === 'task') {
        if (index === 0 || !permission) return false;

        if (permission?.CanOtherComm === 1 && index === 3) return false;
        if (permission?.CanOtherEdit === 1 && index === 1) return false;
        return !(permission?.CanVote === 1 && index === 2);
    } else if (pageType === 'financial') {
        if (index === 0 || !permission) return false;

        if (permission.CanOtherEdit === 1 && index === 1) return false;
        if (permission.CanVote === 1 && index === 2) return false;
        return !(permission.CanOtherComm === 1 && index === 4);
    } else {
        return true;
    }
}


export const SwitcherTab = (
    {
        buttons,
        skip,
        isActive,
        isMultiple = false,
        onClick,
        pageType,
        isDisabled,
        simpleSkipArrayIndex
    }: SwitcherTabProps
) => {

    const handleSelect = (index: number) => {
        onClick(index);
    };

    if (simpleSkipArrayIndex !== undefined) {
        return (
            <div className={styles.container}>
                {buttons.map((button, index) =>
                    <button
                        key={index}
                        disabled={simpleSkipArrayIndex?.includes(index)}
                        style={{width: `${(100 / buttons.length) - 3}%`}}
                        className={isActive === index ? classNames(styles.button, styles.buttonActive) : styles.button}
                        onClick={() => handleSelect(index)}
                    >{button}</button>)}
            </div>
        );
    }

    if (isMultiple) {
        return (
            <div className={styles.container}>
                {buttons.map((button, index) =>
                    <button
                        key={index}
                        style={{width: `${(100 / buttons.length) - 1}%`}}
                        disabled={isSkipIndex(skip as IPermission, index, pageType) || isDisabled}
                        className={(isActive as number[]).includes(index)
                            ? classNames(styles.button, styles.buttonActive)
                            : styles.button}
                        onClick={() => handleSelect(index)}
                    >{button}</button>)}
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {buttons.map((button, index) =>
                <button
                    key={index}
                    style={{width: `${(100 / buttons.length) - 1}%`}}
                    disabled={isSkipIndex(skip as IPermission, index, pageType) || isDisabled}
                    className={isActive === index ? classNames(styles.button, styles.buttonActive) : styles.button}
                    onClick={() => handleSelect(index)}
                >{button}</button>)}
        </div>
    );
};
