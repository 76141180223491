import React from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';

interface ItemCheckboxProps {
    handleOpen: () => void;
    active: boolean;
    isAll?: boolean;
    mode: 'checkbox' | 'dropdown';
}

export const ItemToggle = (
    {
        handleOpen,
        active,
        isAll,
        mode
    }: ItemCheckboxProps
) => {

    return (
        <>
            <div className={styles.checkbox} onClick={(e) => {
                e.stopPropagation();
                handleOpen();
            }}>
                {
                    mode === 'checkbox'
                        ? isAll
                            ? <input
                                type={'checkbox'}
                                readOnly={true}
                                checked={true}
                            /> : <input
                                type={'checkbox'}
                                readOnly={true}
                                checked={active}
                            />
                        : <span
                            className={
                                active
                                    ? classNames(styles.arrowUp, styles.arrowUpUpped)
                                    : styles.arrowUp}/>
                }
            </div>
        </>
    );
};
