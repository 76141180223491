import React, {useState} from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {DynamicTable, ReviewObjectLocation} from '../../store/types';
import {ItemObject} from './ItemObject';

interface TableObjectsProps {
    table?: DynamicTable;
    selectItems: (ids: number[]) => void;
}

export const TableObjects = ({table, selectItems}: TableObjectsProps) => {
    const navigate = useNavigate();
    const location: Location = useLocation();
    const {state} = location as Location & { state: ReviewObjectLocation };

    const [selected, setSelected] = useState<number[]>([]);


    const handleJumpToDetails = (ID_Obj: number) => {
        if (ID_Obj > 0) {
            navigate('/review-objects-list', {state: {...state, FObjects: ID_Obj}});
        } else {
            navigate('/review-objects-list', {state: {...state}});
        }
    };


    const width = (window.innerWidth - 40);
    const firstItemWidth = (width / 4) + 100;
    const itemWidth = (width - (firstItemWidth + 50)) / (2);
    const lastWidth = 50;
    return (
        <>
            <div className={styles.more}>
                <button
                    className={styles.moreButton}
                    disabled={selected.length === 0}
                    onClick={() => selectItems(selected)}
                >Посмотреть несколько
                </button>
            </div>
            <div className={styles.table}>
                <div className={styles.tableHead}>
                    {table?.head.map((head, index) => (
                        <div
                            key={index}
                            style={{
                                width:
                                    index === 0
                                        ? `${firstItemWidth}px`
                                        : index === 3 ? `${lastWidth}px` : `${itemWidth}px`
                            }}
                            className={index === 3
                                ? classNames(styles.tableItem, styles.tableItemLast)
                                : styles.tableItem}>
                            <p className={styles.tableText}>{index === 3 ? '' : head.title}</p>
                        </div>
                    ))}
                </div>
                <>
                    {table?.body.map((item, index) => {

                        return (
                            <div className={styles.tableRowCollapse} key={index}
                                 onClick={() => handleJumpToDetails(item.ID_Obj)}>
                                <div
                                    className={classNames(styles.tableRowInner)}>
                                    <ItemObject
                                        ID_Obj={item.ID_Obj}
                                        text={item.ObjName}
                                        setSelected={(items) => {
                                            setSelected(items);
                                            if (items.length === 0) {
                                                selectItems(items);
                                            }
                                        }}
                                        selected={selected}
                                        isFirst={true}
                                        isLast={false}
                                    />
                                    <ItemObject
                                        ID_Obj={item.ID_Obj}
                                        text={item.cnt.display}
                                        isFirst={false}
                                        isLast={false}
                                    />
                                    <ItemObject
                                        ID_Obj={item.ID_Obj}
                                        text={item.cntLastPeriod.display}
                                        isFirst={false}
                                        isLast={false}
                                    />
                                    <ItemObject
                                        ID_Obj={item.ID_Obj}
                                        text={item.dynamic.display}
                                        isFirst={false}
                                        isLast={true}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </>
            </div>
        </>
    );
};
