import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {DynamicsDate} from '../store/types';

import am5_lang_RU from '@amcharts/amcharts5/locales/ru_RU';

export interface CreateAmChartProps {
    datasets: DynamicsDate[] | DynamicsDate[][];
    isMultiple: boolean;
}

export const createLineChart = ({datasets, isMultiple = false}: CreateAmChartProps): void => {
    // Create root element
    const root = am5.Root.new('dynamic');

    if (root) {
        root.setThemes([am5themes_Animated.new(root)]);

        if (root._logo) {
            root._logo.dispose();
        }

        root.locale = am5_lang_RU;
        // Create chart
        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                layout: root.verticalLayout,
                panX: true,    // Включаем скроллинг по X
                panY: false,   // Отключаем скроллинг по Y
                pinchZoomX: true,  // Включаем масштабирование двумя пальцами по X
                pinchZoomY: false, // Отключаем масштабирование двумя пальцами по Y
                wheelX: 'zoomX',   // Включаем масштабирование колесиком мыши по X
                wheelY: 'none',    // Отключаем масштабирование колесиком по Y
            })
        );

        // Обработчик touch событий для отключения стандартного скролла на мобильных
        chart.chartContainer.events.on('pointerover', (event) => {
            if (event.originalEvent instanceof TouchEvent && event.originalEvent.touches.length > 1) {
                // Если два пальца на экране, блокируем стандартное поведение
                event.originalEvent.preventDefault();
            }
        });

        // Добавляем поддержку жестов для iPhone (двумя пальцами)
        chart.chartContainer.events.on('wheel', (event) => {
            if (event.originalEvent) {
                event.originalEvent.preventDefault();  // Отключаем стандартное поведение колесика
            }
        });

        // Create axes
        const xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                maxDeviation: 0.3,
                groupData: false,
                baseInterval: {
                    timeUnit: 'day',
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, {})
            })
        );

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        // Create series function for single or multiple datasets
        const createSeries = (data: DynamicsDate[], name: string, color: am5.Color) => {
            const series = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name,
                    xAxis,
                    yAxis,
                    valueYField: 'cnt',
                    valueXField: 'timestamp',
                    stroke: color,
                    fill: color,
                    tooltip: am5.Tooltip.new(root, {
                        labelText: '{valueY}'
                    })
                })
            );

            // Add data to the series
            series.data.setAll(data.map(item => ({
                timestamp: item.timestamp * 1000,  // Convert to ms for amCharts
                cnt: item.cnt
            })));

            series.bullets.push(() => {
                // Создаем контейнер для круга и текста
                const container = am5.Container.new(root, {});

                // Создаем круг
                const circle = am5.Circle.new(root, {
                    radius: 15,  // Увеличиваем радиус круга
                    fill: color,
                    stroke: root.interfaceColors.get('background'),
                    strokeWidth: 2
                });

                // Создаем текст для отображения cnt
                const label = am5.Label.new(root, {
                    text: '{valueY}',  // Отображаем значение cnt
                    centerX: am5.p50,  // Центрируем текст по оси X
                    centerY: am5.p50,  // Центрируем текст по оси Y
                    populateText: true,  // Автоматически заполняем текст значением из данных
                    fill: am5.color(0xffffff)  // Белый цвет текста
                });

                // Добавляем круг и текст в контейнер
                container.children.push(circle);
                container.children.push(label);

                // Создаем Bullet и устанавливаем sprite
                return am5.Bullet.new(root, {
                    sprite: container  // Устанавливаем контейнер как sprite для Bullet
                });  // Возвращаем Bullet
            });

        };

        // Handle single or multiple datasets
        if (isMultiple) {
            (datasets as DynamicsDate[][]).forEach((data, index) => {
                const color = chart.get('colors')!.getIndex(index);
                createSeries(data, `Dataset ${index + 1}`, color);
            });
        } else {
            const color = root.interfaceColors.get('primaryButton') ?? am5.color(0x0000ff);
            createSeries(datasets as DynamicsDate[], 'Single Dataset', color);
        }

        // // Add cursor
        chart.set('cursor', am5xy.XYCursor.new(root, {
            behavior: 'none'
        }));

        // Animate chart
        chart.appear(1000, 100);
    }


};
