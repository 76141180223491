import React from 'react';
import styles from './style.module.scss';
import ButtonCard from '../components/buttonCard';
import {ListMenu} from '../screens/home/store/types';

interface QuestionVariableProps {
    iconWidth?: number;
    iconHeight?: number;
    renderData: ListMenu[];
    handlePress: (item: ListMenu) => void;
}

export const QuestionVariable = ({renderData, handlePress, iconWidth = 32, iconHeight = 32}: QuestionVariableProps) => {
    if (renderData.length === 0) {
        return (<div className={styles.container}>
            <p>Скоро здесь появятся варианты выбора.</p>
        </div>);
    }
    return (
        <div className={styles.container}>
            {renderData.map(button => {
                return (
                    <ButtonCard
                        key={button.idForm}
                        className={styles.qCard}
                        name={button.name}
                        iconClass={button.icon.source}
                        src={button.icon.source}
                        type={button.icon.type}
                        iconWidth={iconWidth}
                        iconHeight={iconHeight}
                        onClick={() => handlePress(button)}
                    />
                );
            })}
        </div>
    );
};
