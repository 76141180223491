import {useEffect} from 'react';
import {useAppSelector} from './app.hooks';
import {MonitoringFilterItemReq} from '../screens/monitoring/store/types';
import {IMonitorFilter} from '../screens/monitoring/store/monitoring.slice';

interface UseMonitoringControlProps {
    onFetch: ({params}: { params: string }) => void;
}

interface MonitoringControlReturn {
    clearFilter: () => void;
    applyFilter: () => void;
    filter: IMonitorFilter[];
}

export const useMonitoringControl = (
    {
        onFetch
    }: UseMonitoringControlProps
): MonitoringControlReturn => {
    const {filter} = useAppSelector(state => state.monitor);

    useEffect(() => {
        const params = filter.map(key => {
            if (key.type === 'FObjects') {
                return `${MonitoringFilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
            } else {
                return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
            }
        }).join('&');
        onFetch({params: `${params}`});
    }, [filter]);

    const applyFilter = () => {
        const params = filter.map(key => {
            if (key.type === 'FObjects') {
                return `${MonitoringFilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
            } else {
                return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
            }
        }).join('&');
        onFetch({params});
    };

    const clearFilter = () => {
        const params = filter.map(key => {
            if (key.type === 'FObjects') {
                return `${MonitoringFilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
            } else {
                return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
            }
        }).join('&');
        onFetch({params});
    };

    return {applyFilter, clearFilter, filter};
};
