import React from 'react';
import styles from '../styles.module.scss';
import {useAppSelector} from '../../../hooks/app.hooks';
import {Paragraph} from '../components';
import {Rating} from 'react-simple-star-rating';


const checkTags = (tags: string): string[] => {

    const keys: { [key: string]: string } = {
        'pack': 'Удобство упаковки',
        'temperature': 'Температура еды',
        'quality': 'Вкус и качество блюд',
        'photo-view': 'Вид товара отличается от фото в меню',
        'speed': 'Скорость доставки',
        'communications': 'Коммуникация с курьером',
        'other': 'Остальное',
    };
    const strings: string[] = [];
    const tagsArray = tags.split(',').map(str => str.trim());

    for (const key in keys) {
        if (tagsArray.includes(key)) {
            strings.push(keys[key]);
        }
    }

    return strings;
};

export const replaceText = (str: string) => {
    return str.replace('Примечание:', '').trim();
};

export const Common = () => {
    const {order} = useAppSelector(state => state.order);

    if (!order) {
        return <></>;
    }

    const payType = order.paymentTypes.flatMap((i) => i).pop();

    return (
        <div className={styles.tab}>
            <p className={styles.tabText}>Заказ № {order.orderInfo.number}</p>
            <span className={styles.tabSeparator}/>
            <Paragraph text={order.orderInfo.status} isBold={true} subtext={'Статус:'}/>
            <Paragraph text={order.orderInfo.DateCreated} isBold={false} subtext={'Создан:'}/>
            <Paragraph text={order.orderInfo.DateClosed} isBold={false} subtext={'Закрыт:'}/>
            <Paragraph text={order.objectInfo.Name} isBold={false} subtext={'Ресторан:'}/>
            <Paragraph text={String(order.orderInfo.sum)} isBold={true} subtext={'Сумма:'}/>
            {order.paymentTypes.length > 0 ?
                <Paragraph text={payType?.name ?? ''} isBold={false} subtext={'Тип оплаты:'}/> : null}
            <Paragraph text={replaceText(order.orderRem)} isBold={false} subtext={'Примечание:'}/>
            <span className={styles.tabSeparator}/>
            <Paragraph text={order.customer.name} isBold={false} subtext={'Получатель:'}/>
            <Paragraph text={order.customer.phone} isBold={false} subtext={'Тел:'}/>
            {order.feedback.map(item => {
                const tags = checkTags(item.RatingContent);
                return (
                    <>
                        <span className={styles.tabSeparator}/>
                        <Rating
                            readonly
                            size={20}
                            initialValue={item.Rating}
                        />
                        <Paragraph text={item.Review} isBold={false} subtext={'Отзыв:'}/>
                        <span className={styles.tabSeparator}/>
                        <p className={styles.name}>Теги</p>
                        <div className={styles.tags}>
                            {tags.map((tag, index) => <span key={index} className={styles.tagsItem}>{tag}</span>)}
                        </div>
                    </>
                );
            })}

        </div>
    );
};
