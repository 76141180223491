import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {formatNumber} from '../../helpers';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import SimpleButton from '../../components/simpleButton';
import styles from './styles.module.scss';
import {useLazyGetOrderCountQuery} from './store/monitoring.api';
import {MonitoringFilterItemReq, OrderToday} from './store/types';
import AppLoader from '../../components/appLoader';
import {initDateKeys, setFilter} from './store/monitoring.slice';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {MonitoringFilter} from './components';

export const MonitoringSelect = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {filter} = useAppSelector(state => state.monitor);
    const [getCount, {data: buttons, isFetching}] = useLazyGetOrderCountQuery();

    useEffect(() => {
        const params = filter.map(key => {
            if (key.type === 'FStatuses') {
                return ``;
            } else {
                return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
            }
        }).join('&');
        getCount({params});
    }, []);

    const applyFilter = () => {
        const params = filter.map(key => {
            if (key.type === 'FObjects') {
                return `${MonitoringFilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
            } else {
                return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
            }
        }).join('&');
        getCount({params});
    };

    const clearFilter = () => {
        const params = initDateKeys.map(key => `${MonitoringFilterItemReq[key.type]}=${key.value}`).join('&');
        getCount({params});
    };

    const handleFilterButton = (item: OrderToday) => {
        const existingFilter = filter.find((f: any) => f.type === 'FStatuses');
        if (existingFilter) {
            const updatedFilter = filter.map((f: any) => f.type === 'FStatuses' ? {
                ...f,
                value: item.name_en
            } : f);
            dispatch(setFilter(updatedFilter));
        } else {
            dispatch(setFilter([...filter, {type: 'FStatuses', value: item.name_en}]));
        }

        navigate(`/monitoring-line`);
    };
    return (
        <MainLayout>
            <Header breadCrumbs={'Мониторинг - Сегодня'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={false}
                applyFilter={applyFilter}
                clearFilter={clearFilter}/>
            {
                isFetching
                    ? <AppLoader isFull={false}/>
                    : (
                        <div className={styles.list}>
                            {buttons?.map((item, index) => {
                                return (
                                    <SimpleButton
                                        key={item.name}
                                        column={2}
                                        handler={() => handleFilterButton(item)}
                                    >
                                        <div className={styles.listItem}>
                                            <span className={styles.listTitle}>{item.cnt}</span>
                                            <span className={styles.listText}>{item.name}</span>
                                            <span
                                                className={styles.listText}>Сумма: {formatNumber(item.sum as number)}</span>
                                        </div>
                                    </SimpleButton>
                                );
                            })}
                        </div>
                    )
            }

        </MainLayout>
    );
};

const s = ['Waiting', 'WaitCooking', 'CookingStarted', 'CookingCompleted', 'OnWay', 'Delivered', 'Closed', 'Cancelled'];
