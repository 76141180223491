import React from 'react';
import styles from '../checkTypes/style.module.scss';
import {useAppSelector} from '../../hooks/app.hooks';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {Location, useLocation, useNavigate, useParams} from 'react-router-dom';
import {ChartUser} from '../../assets/customSvg';
import {themColors} from '../../hooks/useChangeTheme';

export const TimeConfirmation = () => {
    const {idForm} = useParams<{ idForm: string }>();
    const location: Location = useLocation();
    const {state} = location as Location & { state: { ObjectName: string } };
    const navigate = useNavigate();
    const {isFound, base64} = useAppSelector(state => state.timeControl);
    const {theme} = useAppSelector(state => state.app);

    return (
        <MainLayout>
            <Header breadCrumbs={'Рабочее место'}/>
            <BackButton text={'Назад'} onClick={() => navigate(`/time-control/${idForm}`)}/>
            <div className={styles.screenShot}>
                {
                    isFound
                        ? (
                            <>
                                <img className={styles.screenShotImage} src={base64 as string} alt={'screenshot'}/>
                                <div className={styles.screenShotTextWrap}>
                                    <p className={styles.screenShotText}>Вы отмечены на рабочем месте</p>
                                </div>
                            </>
                        ) : (
                            <div className={styles.screenShotTextWrap}>
                                <p className={styles.screenShotText}>{`Вы не обнаружены на объекте ${state.ObjectName}`}</p>
                                <ChartUser
                                    color={themColors[theme].color}
                                    fileName={'free-icon-font-sad-tear-7929960.svg'}
                                    width={64}
                                    height={64}
                                />
                            </div>
                        )
                }
            </div>
        </MainLayout>

    );
};
