import {api} from '../../../store/api';
import {AnswerRequest, QuizItem, UploadFileResponse, UploadFileType} from './types';

interface QuizRes {
    status: boolean,
    listQuestions: QuizItem[]
}

export const quizApi = api.injectEndpoints({
    endpoints: build => ({
        getQuiz: build.query<QuizRes, { path: string }>({
            query: ({path}) => ({
                url: `${path}`,
                method: 'GET'
            }),
            transformResponse: (data: QuizRes) => {
                const excludeTypes: string[] = ['calculated', 'folder'];
                const listQuestions = Object.values(data.listQuestions).filter(i => !excludeTypes.includes(i.type.sType));
                return {
                    listQuestions,
                    status: data.status
                };
            }
        }),
        uploadFile: build.mutation<UploadFileResponse, { file: UploadFileType }>({
            query: ({file}) => ({
                url: '/polls/fileHandler.php',
                method: 'POST',
                body: file,
            })
        }),
        saveQuiz: build.mutation<{ status: boolean }, { body: AnswerRequest }>({
            query: ({body}) => ({
                url: '/polls/saveResult.php',
                method: 'POST',
                body,
            })
        }),
        getPdf: build.query<any, { path: string }>({
            query: ({path}) => ({
                url: path,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            transformResponse: (data: any) => {

                if ('reshead' in data) {

                    return {resBody: data.resBody, resHead: data.reshead};
                }
                return data;
            }
        })
    }), overrideExisting: true
});

export const {
    useGetQuizQuery,
    useSaveQuizMutation,
    useUploadFileMutation,
    useLazyGetQuizQuery,
    useGetPdfQuery,
    useLazyGetPdfQuery
} = quizApi;
