import React from 'react';
import styles from '../styles.module.scss';
import {Option} from '../../../store/types';
import classNames from 'classnames';

interface SwitcherTabProps {
    isActiveIndex: string;
    onTab: (index: string) => void;
}


const buttons: Option[] = [
    {label: 'Основная', value: '0'},
    {label: 'Доставка', value: '1'},
    {label: 'Товары', value: '2'},
    {label: 'Время', value: '3'},
];

export const SwitcherTab = (
    {
        isActiveIndex,
        onTab
    }: SwitcherTabProps
) => {


    return (
        <div className={styles.switcher}>
            {buttons.map(item => (
                <button
                    key={item.value}
                    className={
                        item.value === isActiveIndex
                            ? classNames(styles.switcherButton, styles.switcherActive)
                            : styles.switcherButton
                    }
                    onClick={() => onTab(item.value)}
                >{item.label}</button>
            ))}
        </div>
    );
};
