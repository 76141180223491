import React from 'react';
import styles from './style.module.scss';
import {Select, TreeSelect} from 'antd';
import classNames from 'classnames';
import {Option} from '../../store/types';
import {TreeNode} from '../taskFilter/store/type';

interface SelectFormRowProps {
    options: Option[] | TreeNode[] | undefined;
    label: string;
    isTreeOptions: boolean;
    error?: string | undefined;
    mode?: 'multiple' | 'tags';
    disabled: boolean;
    defaultValue: string;
    handleSelect: (value: string) => void;
    handleDeselect?: (value: string) => void;
}

const {SHOW_CHILD} = TreeSelect;

export const SelectFormRow = (
    {
        options,
        label,
        defaultValue,
        error,
        isTreeOptions,
        disabled,
        mode,
        handleSelect,
        handleDeselect
    }: SelectFormRowProps
) => {

    return (
        <div className={styles.taskFormRow}>
            <div className={styles.taskFormRowLabelBox}>
                <label className={styles.taskLabel}>{label}</label>
            </div>
            <div className={styles.taskFormRowInputBox}>
                {
                    isTreeOptions
                        ? (<TreeSelect
                            showSearch
                            multiple={false}
                            disabled={disabled}
                            treeData={options as TreeNode[] ?? []}
                            className={!options || disabled
                                ? classNames(styles.select, styles.selectDisabled)
                                : error ? classNames(styles.select, styles.selectError) : styles.select
                            }
                            //treeDefaultExpandAll
                            treeCheckable={false}
                            showCheckedStrategy={SHOW_CHILD}
                            treeNodeFilterProp="title"
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            listHeight={300}
                            placeholder="Выбрать"
                            allowClear
                            value={defaultValue}
                            onChange={(newValue: string) => handleSelect(newValue)}
                        />)
                        : mode ? (
                            <Select
                                //showSearch
                                mode={mode}
                                allowClear
                                placeholder={'Выбрать'}
                                className={!options || disabled
                                    ? classNames(styles.select, styles.selectDisabled)
                                    : error ? classNames(styles.select, styles.selectError) : styles.select
                                }
                                options={options as Option[] ?? []}
                                disabled={disabled}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                                value={defaultValue}
                                onDeselect={(value, option) => {
                                    handleSelect(value);
                                }}
                                onSelect={(value, option) => {

                                    handleSelect(value);
                                }}
                            />
                        ) : (
                            <Select
                                showSearch
                                placeholder={'Выбрать'}
                                className={!options || disabled
                                    ? classNames(styles.select, styles.selectDisabled)
                                    : error ? classNames(styles.select, styles.selectError) : styles.select
                                }
                                options={options as Option[] ?? []}
                                disabled={disabled}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                                value={defaultValue}
                                onSelect={(value, option) => {
                                    handleSelect(value);
                                }}
                            />
                        )
                }
                {error ? <p className={styles.errorMessage}>{error}</p> : null}
            </div>
        </div>
    );
};
