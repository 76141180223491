import React from 'react';
import {useAppSelector} from '../../../hooks/app.hooks';
import styles from '../styles.module.scss';
import {Paragraph} from '../components';

export const TimePing = () => {
    const {order} = useAppSelector(state => state.order);

    if (!order) {
        return <></>;
    }

    const steps = order.table.body[0];

    return (
        <div className={styles.tab}>
            <Paragraph text={steps.OrderStatus} isBold={true} subtext={'Текущий статус:'}/>
            <div className={styles.tabSeparator}/>
            {steps.DateCreated.display
                ? <Paragraph text={steps.DateCreated.display} isBold={false} subtext={'Создан:'}/> : null}
            {steps.DateCompleteBefore.display
                ? <Paragraph text={steps.DateCompleteBefore.display} isBold={false} subtext={'К сроку:'}/> : null}
            {steps.DateCookingStartTime.display
                ? <Paragraph text={steps.DateCookingStartTime.display} isBold={false}
                             subtext={'Начало готовки:'}/> : null}
            {steps.DateCookingCompleted.display
                ? <Paragraph text={steps.DateCookingCompleted.display} isBold={false} subtext={'Пригоровлен:'}/> : null}
            {steps.DateSended.display
                ? <Paragraph text={steps.DateSended.display} isBold={false} subtext={'Отправлен:'}/> : null}
            {steps.DateDelivered.display
                ? <Paragraph text={steps.DateDelivered.display} isBold={false} subtext={'Доставлен:'}/> : null}
            {steps.DateClosed.display
                ? <Paragraph text={steps.DateClosed.display} isBold={false} subtext={'Закрыт:'}/> : null}
            {steps.CloseTg
                ? <Paragraph text={steps.CloseTg} isBold={false} subtext={'Закрыт TG:'}/> : null}
            {steps.ResponceTG
                ? <Paragraph text={steps.ResponceTG} isBold={false} subtext={'Доставлен TG:'}/> : null}

        </div>
    );
};
