import React, {useEffect, useId} from 'react';
import {createChart} from '../lib';
import {ChatOpt} from '../store/types';

interface ChartProps {
    chart: ChatOpt[];
    handleClickByLegend: (name: string, value: string) => void;
}

export const Chart = ({chart, handleClickByLegend}: ChartProps) => {
    const chartId = useId();

    useEffect(() => {
        const root = createChart(chartId, chart, handleClickByLegend);

        return () => {
            root.dispose();
        };
    }, []);

    return <div id={chartId} style={{width: '100%', height: '340px'}}/>;
};
