import React, {useId, useLayoutEffect} from 'react';
import styles from '../style.module.scss';
import {IReport} from '../store/types';
import {ArrowDownOutlined, ArrowUpOutlined} from '@ant-design/icons';
import {createChartRange} from '../createChartRange';

import {useLocation, useNavigate} from 'react-router-dom';
import {resetGlobalAbortController} from '../../../store/abortController';
import {extractValueFromString, formatNumberAndPostfix} from '../../../helpers';
import {typeFlow} from '../../../store/types';


interface CardProps {
    data: IReport;
    isPercent?: boolean;
    isPlan: boolean;
}

export const Card = ({data, isPercent = false, isPlan = false}: CardProps) => {
    const chartId = useId();
    const navigate = useNavigate();
    const {search} = useLocation();
    const retortType = extractValueFromString(search, 'typeMob');

    useLayoutEffect(() => {
        // const root = createChart(chartId, data?.chart ?? [])
        const root = createChartRange(chartId, data?.chart ?? [], isPlan);

        return () => {
            root.dispose();
        };
    }, [data, isPlan]);

    const jumpToViewReport = () => {
        if (retortType === typeFlow.mainValuesRevenue) {
            resetGlobalAbortController();
            navigate('/report-view');
        }
    };

    return (
        <div className={styles.card} onClick={jumpToViewReport}>
            <div className={styles.cardBody}>
                <p className={styles.cardTitle}>{data?.name ?? 'Пока нет данных'}</p>
                <div className={styles.data}>
                    {
                        isPercent
                            ? <span className={styles.dataRevenue}>{`${data?.value ?? 0}%`}</span>
                            : <span className={styles.dataRevenue}>{formatNumberAndPostfix(data?.value ?? 0)}</span>
                    }
                    <div className={styles.dataChecksWrap}>
                        <p className={styles.dataChecks}
                           style={{
                               color: Number(data?.percent ?? 0) > 0 ? '#2E7303' : '#FF0000'
                           }}>
                            {Number(data?.percent ?? 0) > 0
                                ? <ArrowUpOutlined/>
                                : <ArrowDownOutlined/>}
                            <span>{`${data?.percent?.toFixed(1) ?? 0} %`}</span>
                        </p>
                    </div>

                </div>
            </div>
            <div className={styles.cardChart}>
                <div id={chartId} style={{width: '100%', height: '85px'}}/>
            </div>
        </div>
    );
};
