import React, {useState} from 'react';
import styles from '../style.module.scss';
import {ITaskReq, Signature, TaskType} from '../store/types';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {TextareaControl} from '../../../fields/textarea';
import {useActionTaskMutation} from '../store/task.api';
import {fromJsonToUrlencoded} from '../../../helpers';
import classNames from 'classnames';
import {Button} from '../../../fields/button';
import AppLoader from '../../../components/appLoader';
import {themColors} from '../../../hooks/useChangeTheme';
import {useAppSelector} from '../../../hooks/app.hooks';

const successIcon = require('../../../assets/images/signature/succses.png');
const rejectIcon = require('../../../assets/images/signature/reject.png');
const questionIcon = require('../../../assets/images/signature/question.png');

interface SignaturesProps {
    signatures: any;
    isLoading: boolean;
    ID_Sect: string;
    ID_Obj: string;
    ID_App: string;
    onChange: () => void;
    isEdit: boolean;
}

function renderIconSource(type: number) {
    if (type < 0) {
        return rejectIcon;
    }
    if (type > 0) {
        return successIcon;
    }
    return questionIcon;
}

function capitalizeFirstLetters(str: string) {
    if (str.includes(' ')) {
        // Если строка содержит пробелы
        const words = str.split(' ');
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase());
        return capitalizedWords.join('');
    } else {
        // Если строка не содержит пробелов
        return str.charAt(0).toUpperCase();
    }
}

const buttonSubmit = ['Подтвердить', 'Воздержаться', 'Отказать'];

const schemaForm = yup.object().shape({
    RemVote: yup.string().required('оставите комментарий')
});

export const Signatures = ({signatures, isLoading, ID_Obj, ID_Sect, ID_App, onChange, isEdit}: SignaturesProps) => {
    const [saveTask] = useActionTaskMutation();
    const [voteType, setVoteType] = useState<TaskType.Accept | TaskType.Defer | TaskType.Reject>(TaskType.Accept);
    const [isIndex, setIndex] = useState<number>(1);
    const {control, setValue, reset, handleSubmit, clearErrors} = useForm({
        resolver: yupResolver(schemaForm)
    });

    const {theme} = useAppSelector(state => state.app);

    const submit = handleSubmit(values => {
        const data: ITaskReq = {
            Type: String(voteType),
            ID_Type: '2',
            SelectRow: '1',
            ID_Form: '115',
            ID_State: '1',
            ID_Sect: ID_Sect,
            ID_Obj: ID_Obj,
            ID_App: ID_App,
            RemVote: values.RemVote,
        };

        saveTask({
            data: fromJsonToUrlencoded({data})
        }).unwrap().then(() => {
            onChange();
            reset();
        });
    });

    const handleSwitchType = (index: number) => {
        setIndex(index);
        switch (index) {
            case 1:
                return setVoteType(TaskType.Defer);
            case 2:
                return setVoteType(TaskType.Reject);
            default:
                return setVoteType(TaskType.Accept);
        }
    };

    if (isLoading) {
        return <AppLoader isFull={false}/>;
    }

    return (
        <>
            <div className={styles.signature}>
                {signatures?.map((i: Signature) => (
                    <div key={i.ID} className={styles.signatureItem}>
                        <div className={styles.signatureAvatar}>
                            <span className={styles.signatureAvatarText}>{capitalizeFirstLetters(i.Name)}</span>
                        </div>
                        <div className={styles.signatureBody}>
                            <div className={styles.signatureTitleWrap}>
                                <span className={styles.signatureTitle}>{i.Name}</span>
                            </div>
                            <span className={styles.signatureVote}>{i.Rem}</span>
                            <span className={styles.signatureDate}>{i.DateVote_str.split(' ')[0]}</span>
                        </div>
                        <div className={styles.icon}>
                            <img src={renderIconSource(i.Vote)} alt={'запись'}/>
                        </div>
                    </div>
                ))}
                <div>
                    <TextareaControl
                        fieldName={'RemVote'}
                        control={control}
                        rows={2}
                        onChange={value => {
                            setValue('RemVote', String(value));
                            clearErrors('RemVote');
                        }}/>
                    <div className={styles.container}>
                        {buttonSubmit.map((button, index) =>
                            <button
                                key={index}
                                className={isIndex === index ? classNames(styles.button, styles.buttonActive) : styles.button}
                                onClick={() => handleSwitchType(index)}
                            >{button}</button>)}
                    </div>
                </div>
            </div>
            {isEdit ? <div className={styles.bottomWrapper}>
                <Button
                    text={'Сохранить'}
                    textStyleType={'semiBold'}
                    style={{
                        color: themColors[theme]?.color,
                        fontSize: '16px',
                        borderRadius: '12px',
                        border: `1px solid ${themColors[theme]?.color}`
                    }}
                    onClick={submit}
                />
            </div> : null}
        </>
    );
};
