import React from 'react';
import styles from './style.module.scss';
import {ItemToggle} from './ItemToggle';
import classNames from 'classnames';

interface ItemObjectProps {
    selected?: number[];
    setSelected?: (selected: number[]) => void;
    ID_Obj: number;
    text: string;
    isFirst: boolean;
    isLast: boolean;
}

export const ItemObject = (
    {
        selected,
        setSelected,
        ID_Obj,
        text,
        isFirst,
        isLast
    }: ItemObjectProps
) => {
    const width = (window.innerWidth - 40);
    const firstItemWidth = (width / 4) + 100;
    const itemWidth = (width - (firstItemWidth + 50)) / (2);
    const lastWidth = 50;
    return (
        <div
            style={{width: isFirst ? `${firstItemWidth}px` : isLast ? `${lastWidth}px` : `${itemWidth}px`}}
            className={isFirst ? styles.tableItem : classNames(styles.tableItem, styles.tableItemCenter)}>
            {isFirst && selected && setSelected
                ? (<ItemToggle
                    mode={'checkbox'}
                    isAll={selected.length === 0 && Math.sign(ID_Obj) !== 1}
                    handleOpen={() => {
                        if (selected.includes(ID_Obj)) {
                            const arr = selected.filter(i => i !== ID_Obj);
                            setSelected(arr);
                        } else {
                            setSelected([...selected, ID_Obj]);
                        }
                    }}
                    active={selected.includes(ID_Obj)}
                />) : null}
            <p className={styles.tableTitle}>{text}</p>
        </div>
    );
};
