import {createSlice} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {ReviewState} from './types';

const today = dayjs();
const weekAgo = dayjs().subtract(1, 'week');
const monthAgo1 = weekAgo.subtract(1, 'month');
const monthAgo2 = today.subtract(1, 'month');

export const dateKeys = [
    {type: 'Start', value: weekAgo.format('YYYY-MM-DD')},
    {type: 'Finish', value: today.format('YYYY-MM-DD')},
    {type: 'Start2', value: monthAgo1.format('YYYY-MM-DD')},
    {type: 'Finish2', value: monthAgo2.format('YYYY-MM-DD')},

];

const initialState: ReviewState = {
    filter: dateKeys
};

const reviewSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {
        setReviewsFilter: (state, {payload}) => {
            state.filter = payload;
        }
    }
});

export const {setReviewsFilter} = reviewSlice.actions;

export default reviewSlice.reducer;
