import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useLazyGetByStageQuery} from './store/monitoring.api';
import {useMonitoringControl} from '../../hooks';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MonitoringDataCard, MonitoringFilter} from './components';
import AppLoader from '../../components/appLoader';
import styles from './styles.module.scss';
import Footer from '../../components/footer';

export const MonitoringByStep = () => {
    const navigate = useNavigate();

    const [getByStep, {data, isLoading}] = useLazyGetByStageQuery();

    const {applyFilter, clearFilter} = useMonitoringControl({
        
        onFetch: ({params}) => getByStep({params})
    });

    return (
        <MainLayout>
            <Header breadCrumbs={'Мониторинг - Время по этапам за период'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={false}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {isLoading
                ? <AppLoader isFull={false}/>
                : (

                    <div className={styles.list}>
                        <MonitoringDataCard
                            column={2}
                            num={data?.AvgTimeCooking}
                            text={'Готовка'}
                            postfix={'мин.'}/>
                        <MonitoringDataCard
                            column={2}
                            num={data?.AvgTimeOnShelf}
                            text={'Ср. время на полке'}
                            postfix={'мин.'}/>

                        <MonitoringDataCard
                            column={2}
                            num={data?.AvgTimeSended}
                            text={'Доставка'}
                            postfix={'мин.'}/>
                        <MonitoringDataCard
                            column={2}
                            num={data?.ClickToEat}
                            text={'ClickToEat'}
                            postfix={'мин.'}
                        />
                    </div>
                )}
            <Footer/>
        </MainLayout>
    );
};
