import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import Footer from '../../components/footer';
import {useLazyGetActionListQuery} from './store/action.list.api';
import styles from './style.module.scss';
import {Card, Filter} from './components';
import {ScrollUp} from '../../components/scrollUp';
import AppLoader from '../../components/appLoader';
import {formatDate, fromJsonToUrlencoded} from '../../helpers';
import {ActionFilterItemReq, ActionItem} from './store/types';
import {FinancialState} from '../financial/FinancialList';
import {FilterItemType, IFilter} from '../../components/taskFilter/store/type';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {useGetFilterObjMutation} from '../../components/financialFilter/store/financial.filter.api';
import {dateKeys, setActionFilter, setClearActionFilter} from './store/action.slice';

export const ActionList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [getFilter, {data: objects}] = useGetFilterObjMutation({fixedCacheKey: 'objectFilter'});
    const [getList, {data, isLoading}] = useLazyGetActionListQuery();

    const {keys} = useAppSelector(state => state.actions);

    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    useEffect(() => {
        const params = keys.map(key => `${ActionFilterItemReq[key.type]}=${key.value}`).join('&');
        getList({params});
        getFilter({body: fromJsonToUrlencoded({data: {ShowComp: 'NULL', flagSHID: false}})});

    }, []);

    const handleChangeDate = (dateStrings: [string, string]) => {
        const formattedDates = dateStrings;

        setIsCollapsed(true);

        const newKeys = keys.map(key => {
            if (key.type === 'Start') return {...key, value: formattedDates[0]};
            if (key.type === 'Finish') return {...key, value: formattedDates[1]};
            return key;
        });
        dispatch(setActionFilter(newKeys));
    };

    const handlePressCard = (data: ActionItem) => {
        if (data.task !== null) {
            const item = data.task;
            const date = new Date(item.DateApp.date.replace(' ', 'T'));

            const state = {
                ID_Sect: item.ID_Sect,
                ID_Obj: item.ID_Obj,
                ID_Solver: item.ID_Solver,
                ID_Priority: item.ID_Priority,
                Name: item.Name,
                Rem: item.Rem,
                ID_App: item.ID,
                ID_State: item.ID_State,
                AuthorName: item.AuthorName,
                date: item.DI.date,
                DateDeadline: formatDate({date, type: 'YYYY-MM-DD'}),
            };
            return navigate(`/task-create?ID_App=${item.ID}`, {state});
        }

        if (data.finApp !== null) {
            const item = data.finApp;
            const state: FinancialState = {
                ID_App: String(item.ID),
                ID_Obj: String(item.ID_Obj)
            };
            return navigate('/financial-create', {state});
        }
    };

    const handleSetFilter = (value: string, type: FilterItemType | 'index') => {
        if (keys.some(key => key.type === type)) {
            const newKeys = keys.map(key => key.type === type ? {...key, value} : key);
            dispatch(setActionFilter(newKeys));
        } else {
            dispatch(setActionFilter([...keys, {type, value}]));
        }
    };

    const applyFilter = () => {
        const params = keys.map(key => `${ActionFilterItemReq[key.type]}=${key.value}`).join('&');
        getList({params});
    };

    const clearFilter = () => {
        dispatch(setClearActionFilter());
        setIsCollapsed(false);
        const params = dateKeys.map(key => `${ActionFilterItemReq[key.type]}=${key.value}`).join('&');
        getList({params});
    };

    const dateStart = keys.find(k => k.type === 'Start') as IFilter;
    const dateFinish = keys.find(k => k.type === 'Finish') as IFilter;

    return (
        <MainLayout>
            <Header breadCrumbs={'Лента событий'}/>
            <BackButton text={'Назад'} onClick={() => navigate('/')}/>
            <Filter
                objects={objects?.listObject ?? []}
                keys={keys}
                valueDate={[dateStart.value as string, dateFinish.value as string]}
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                handleSelectDate={handleChangeDate}
                handleSelectObj={value => handleSetFilter(value, 'ID_Obj')}
                clearFilter={clearFilter}
                applyFilter={applyFilter}
            />
            {isLoading ? <AppLoader isFull={false}/> : (
                <div className={styles.list}>
                    {data?.map((i, index) => {

                        const description = i.descript.split(/(\r\n|\r|\n|<br\s*\/?>)/gi,);
                        return (
                            <Card
                                arrayText={description}
                                date={i.date.split(' ')[0]}
                                time={i.date.split(' ')[1]}
                                handlePressCard={() => handlePressCard(i)}/>
                        );
                    })}
                </div>
            )}

            {data && data.length > 15 ? <ScrollUp/> : null}
            <Footer/>
        </MainLayout>
    );
};
