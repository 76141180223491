import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useLazyGetBySourceQuery} from './store/monitoring.api';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MonitoringDataCard, MonitoringFilter} from './components';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {useMonitoringControl} from '../../hooks';
import AppLoader from '../../components/appLoader';
import {formatNumber} from '../../helpers';
import {calculatePercentage} from '../../helpers/calculatePercentage';

export const MonitoringBySource = () => {
    const navigate = useNavigate();

    const [getBySource, {data, isLoading}] = useLazyGetBySourceQuery();

    const {applyFilter, clearFilter} = useMonitoringControl({
        
        onFetch: ({params}) => getBySource({params})
    });

    return (
        <MainLayout>
            <Header breadCrumbs={'Мониторинг - По источникам за период'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={false}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <>
                            <div className={styles.wrapper}>
                                <p className={styles.title}>
                                    Выручка всего:
                                    <span className={styles.titleSum}>{formatNumber(data?.total.sum ?? 0)}</span>
                                    <span className={styles.titlePercent}>(100%)</span>
                                </p>
                            </div>
                            <div className={styles.list}>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.dominoWEB.sum}
                                    percent={calculatePercentage(data?.total.sum as number, data?.dominoWEB.sum as number)}
                                    text={'Web'}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.dominoAPP.sum}
                                    percent={calculatePercentage(data?.total.sum as number, data?.dominoAPP.sum as number)}
                                    text={'App'}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.inStore.sum}
                                    percent={calculatePercentage(data?.total.sum as number, data?.inStore.sum as number)}
                                    text={'In Store'}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.aggregators.sum}
                                    percent={calculatePercentage(data?.total.sum as number, data?.aggregators.sum as number)}
                                    text={'Агрегатор'}/>
                            </div>
                            <div className={styles.listBox}>
                                <p className={styles.title}>Агрегаторы :</p>
                            </div>
                            <div className={styles.list}>
                                <MonitoringDataCard
                                    column={3}
                                    num={data?.delivery.sum}
                                    percent={calculatePercentage(data?.total.sum as number, data?.delivery.sum as number)}
                                    text={'Доставка'}/>
                                <MonitoringDataCard
                                    column={3}
                                    num={data?.sbermarket.sum}
                                    percent={calculatePercentage(data?.total.sum as number, data?.sbermarket.sum as number)}
                                    text={'Сбер'}/>
                                <MonitoringDataCard
                                    column={3}
                                    num={data?.yandex.sum}
                                    percent={calculatePercentage(data?.total.sum as number, data?.yandex.sum as number)}
                                    text={'Яндекс'}/>
                            </div>
                            <div className={styles.listBox}>
                                <div className={styles.separator}/>
                                <p className={styles.title}>
                                    Чеков всего:
                                    <span className={styles.titleSum}>{formatNumber(data?.total.checks ?? 0)}</span>
                                    <span className={styles.titlePercent}>(100%)</span>
                                </p>
                            </div>

                            <div className={styles.list}>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.dominoWEB.checks}
                                    percent={calculatePercentage(data?.total.checks as number, data?.dominoWEB.checks as number)}
                                    text={'Web'}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.dominoAPP.checks}
                                    percent={calculatePercentage(data?.total.checks as number, data?.dominoAPP.checks as number)}
                                    text={'App'}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.inStore.checks}
                                    percent={calculatePercentage(data?.total.checks as number, data?.inStore.checks as number)}
                                    text={'In Store'}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.aggregators.checks}
                                    percent={calculatePercentage(data?.total.checks as number, data?.aggregators.checks as number)}
                                    text={'Агрегатор'}/>
                            </div>
                            <div className={styles.listBox}>
                                <p className={styles.title}>Агрегаторы:</p>
                            </div>
                            <div className={styles.list}>
                                <MonitoringDataCard
                                    column={3}
                                    num={data?.delivery.checks}
                                    percent={calculatePercentage(data?.total.checks as number, data?.delivery.checks as number)}
                                    text={'Доставка'}/>
                                <MonitoringDataCard
                                    column={3}
                                    num={data?.sbermarket.checks}
                                    percent={calculatePercentage(data?.total.checks as number, data?.sbermarket.checks as number)}
                                    text={'Сбер'}/>
                                <MonitoringDataCard
                                    column={3}
                                    num={data?.yandex.checks}
                                    percent={calculatePercentage(data?.total.checks as number, data?.yandex.checks as number)}
                                    text={'Яндекс'}/>
                            </div>
                        </>
                    )
            }

            <Footer/>
        </MainLayout>
    );
};
