import React from 'react';
import styles from './style.module.scss';
import {ItemToggle} from './ItemToggle';
import classNames from 'classnames';

type ItemRatingType = {
    name: string;
    avgRating: string;
    qntNegative: string;
    negativePercent: string;
    isExpanded?: boolean;
    setExpanded?: (status: boolean) => void;
    isInner: boolean;
    mode?: 'dropdown' | 'checkbox';
    col: number;
    handleSelect: () => void;
}

export const ItemOpt = (
    {
        name,
        avgRating,
        qntNegative,
        negativePercent,
        isExpanded,
        setExpanded,
        handleSelect,
        mode,
        col,
        isInner,
    }: ItemRatingType
) => {


    const width = (window.innerWidth - 40);
    const firstItemWidth = isInner ? (width / col) + 68 : (width / col) + 80;
    const itemWidth = (width - firstItemWidth) / (col - 1);
    return (
        <>
            <div
                className={isInner
                    ? classNames(styles.tableRowInner, styles.tableRowInnerPadding)
                    : styles.tableRowInner}>
                <div
                    style={{
                        width: `${firstItemWidth}px`,
                        padding: !mode ? '3px 10px 3px 35px' : '0'
                    }}
                    className={styles.tableItem}
                    onClick={handleSelect}
                >
                    {isExpanded !== undefined && mode !== undefined ? <ItemToggle
                        handleOpen={() => setExpanded ? setExpanded(!isExpanded) : null}
                        active={isExpanded}
                        mode={mode}
                    /> : null}
                    <p className={styles.tableTitle}>{name}</p>
                </div>
                <div
                    style={{width: `${itemWidth}px`}}
                    className={classNames(styles.tableItem, styles.tableItemCenter)}
                    onClick={handleSelect}
                >
                    <p className={styles.tableTitle}>{avgRating}</p>
                </div>
                <div
                    style={{width: `${itemWidth}px`}}
                    className={classNames(styles.tableItem, styles.tableItemCenter)}
                    onClick={handleSelect}
                >
                    <p className={styles.tableTitle}>{qntNegative}</p>
                </div>
                <div
                    style={{width: `${itemWidth}px`}}
                    className={classNames(styles.tableItem, styles.tableItemCenter)}
                    onClick={handleSelect}
                >
                    <p className={styles.tableTitle}>{negativePercent}</p>
                </div>
            </div>
        </>
    );
};
