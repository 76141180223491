import {api} from '../../../store/api';
import {LoginRequest, LoginResponse} from './types';
import {CONTENT_TYPE_URLENCODED} from '../../../utils/constants';

export const authApi = api.injectEndpoints({
    endpoints: build => ({
        login: build.mutation<LoginResponse, LoginRequest>({
            query: ({formData}) => ({
                url: '/authorization/login.php',
                method: 'POST',
                body: formData,
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            })
        }),
        checkSession: build.query<{ status: boolean }, void>({
            query: () => ({
                url: '/authorization/checkSession.php',
                method: 'GET'
            })
        })
    }), overrideExisting: true
});


export const {useLoginMutation, useCheckSessionQuery} = authApi;

