import React from 'react';
import {useGetWorkShiftQuery} from './store/api.timeControl';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {useNavigate} from 'react-router-dom';
import styles from './style.module.scss';
import AppLoader from '../../components/appLoader';
import Footer from '../../components/footer';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import {DatePicker} from 'antd';

const {RangePicker} = DatePicker;

const tableHead = ['имя', 'пришёл', 'ушёл', ''];

export const WorkShift = () => {
    const navigate = useNavigate();
    const {data, isLoading} = useGetWorkShiftQuery();

    const handleJumpToEmployee = () => {
        navigate('/time-work-shift-employee');
    };
    return (
        <MainLayout>
            <Header breadCrumbs={'На смене'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.wrapper}>
                <div>
                    <RangePicker
                        popupClassName="custom-range-picker-dropdown"
                        className={styles.inputRange}
                        locale={ru_RU}
                        format="D MMM, YYYY"
                        value={[dayjs(), dayjs()]}
                        onChange={(dates, dateStrings) => {
                        }}
                        separator="-"
                        disabled={true}
                        allowClear={false}
                    />
                </div>
                {isLoading ? <AppLoader isFull={false}/> :
                    data?.map(object => {
                        return (
                            <div key={object.ID_Obj} className={styles.object}>
                                <p className={styles.objectName}>
                                    {object.ObjName}
                                </p>
                                <div className={styles.objectTableWrap}>
                                    <div className={styles.table}>
                                        <div className={styles.tableHeader}>
                                            {tableHead.map((item, index) =>
                                                <div key={index} className={styles.tableHead}>
                                                    <span className={styles.tableHeadText}>{item}</span>
                                                </div>)}
                                        </div>

                                        {object.employees.map((emp, idx) => {
                                            return (
                                                <div key={idx} className={styles.tableRow}>
                                                    <div className={styles.tableRowItem}>
                                                        <span className={styles.tableRowName}>{emp.KontraName}</span>
                                                        <span className={styles.tableRowText}>{emp.StaffName}</span>
                                                    </div>
                                                    <div className={styles.tableRowItem}>
                                                        <span className={styles.tableRowText}>{emp.in}</span>
                                                    </div>
                                                    <div className={styles.tableRowItem}>
                                                        <span className={styles.tableRowText}>{emp.out}</span>
                                                    </div>
                                                    <div className={styles.tableRowItem}>
                                                        <button className={styles.tableRowBtn}
                                                                onClick={handleJumpToEmployee}>смотреть
                                                        </button>
                                                    </div>

                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <Footer/>
        </MainLayout>
    );
};
