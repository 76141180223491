import {ListMenu} from '../screens/home/store/types';

export const getObjectByIdForm = (array: ListMenu[] = [], idForm: number): ListMenu | null => {
    if (!idForm) return null;

    let result: ListMenu | null = null;
    for (let i = 0; i < array.length; i++) {
        const obj = array[i];
        if (obj.idForm === idForm) {
            return obj;
        }
        if (obj.children && obj.children.length > 0) {
            const foundInChildren = getObjectByIdForm(obj.children, idForm);
            if (foundInChildren) {
                result = foundInChildren;
            }
        }
    }
    return result as ListMenu;
};
