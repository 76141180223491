import React from 'react';
import {useNavigate} from 'react-router-dom';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './style.module.scss';

export const WorkShiftEmployee = () => {
    const navigate = useNavigate();
    return (
        <MainLayout>
            <Header breadCrumbs={'Учет времени сотрудника'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.wrapper}>
                <p className={styles.objectName}>Страница в разработке</p>
            </div>
        </MainLayout>
    );
};

