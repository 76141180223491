import {createSlice} from '@reduxjs/toolkit';
import {MonitoringFilterItemType, MonitoringStore} from './types';
import dayjs from 'dayjs';
import {monitoringApi} from './monitoring.api';

const today = dayjs();

export interface IMonitorFilter {
    type: MonitoringFilterItemType | 'index',
    value: string | string[]
};
export const initDateKeys: IMonitorFilter[] = [
    {type: 'Start', value: today.format('YYYY-MM-DD')},
    {type: 'Finish', value: today.format('YYYY-MM-DD')},
];

const initialState: MonitoringStore = {
    filter: initDateKeys,
    pointsOrders: [],
    statuses: []
};
const monitoringSlice = createSlice({
    name: 'monitoring',
    initialState,
    reducers: {
        setFilter: (state, {payload}) => {
            state.filter = payload;
        },
        setClearFilter: (state) => {
            state.filter = initDateKeys;
        },
        changePointOrders: (state, {payload}) => {
            state.pointsOrders = payload;
        },
        setStatuses: (state, {payload}) => {
            state.statuses = payload;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            monitoringApi.endpoints.getPointAll.matchFulfilled,
            (state, {payload}) => {
                state.pointsOrders = payload;
            }
        );
    }
});

export const {
    setFilter,
    setClearFilter,
    changePointOrders,
    setStatuses
} = monitoringSlice.actions;

export default monitoringSlice.reducer;
