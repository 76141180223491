import React from 'react';
import {OrderToday} from '../store/types';
import styles from '../styles.module.scss';
import {formatNumber} from '../../../helpers';

export const OrderTodayItem = ({items}: { items: OrderToday[] }) => {
    return <>
        {items.map((item, index) => {
            if (!item.name) {
                return '';
            }
            if (item?.value) {
                return (
                    <div key={index} className={styles.listOrdersItem}>
                        <p className={styles.listOrdersName}>{item.name}</p>
                        <p className={styles.listOrdersBottom}>{item.value}</p>
                    </div>
                );
            }
            return (
                <div key={index} className={styles.listOrdersItem}>
                    <div className={styles.listOrdersItemFooter}>
                        <p className={styles.listOrdersName}>{item.name}</p>
                        <p className={styles.listOrdersValue}>Кол-во {item.cnt}</p>
                    </div>
                    <p className={styles.listOrdersBottom}>Сумма: {formatNumber(item.sum as number ?? 0)}</p>
                </div>
            );
        })}</>;
};
