import {ITask} from '../../taskCreate/store/types';
import {Financial} from '../../financial/state/types';

export type ActionFilter = 'ID_Obj' | 'Start' | 'Finish';

export const ActionFilterItemReq: any = {
    ID_Obj: 'FObjects',
    Start: 'Start',
    Finish: 'Finish',
};

export interface IActionFilter {
    type: ActionFilter | 'index',
    value: string
};


export interface ActionItem {
    idSource: number;
    idApp: number;
    idObj: number;
    nameObj: string;
    descript: string;
    date: string;
    task: ITask | null;
    finApp: Financial | null;
}


export interface ActionsState {
    keys: IActionFilter[];
}
