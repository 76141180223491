import {createSlice} from '@reduxjs/toolkit';
import {TimeControlState} from './types';


const initialState: TimeControlState = {
    isFound: false,
    message: undefined,
    base64: null
};

const timeControlSlice = createSlice({
    name: 'timeControl',
    initialState,
    reducers: {
        changeFoundStatus: (state, {payload}) => {
            state.isFound = payload;
            state.message = undefined;
            state.base64 = null;
        }
    },
    // extraReducers: builder => {
    //     builder.addMatcher(
    //         apiTimeControl.endpoints.checkByCamera.matchFulfilled,
    //         (state, {payload}) => {
    //             // const {message, image, avatar, score, result} = payload;
    //             state.isFound = payload.result;
    //             state.message = payload.message;
    //             state.base64 = payload.image;
    //         }
    //     );
    // }
});

export const {changeFoundStatus} = timeControlSlice.actions;
export default timeControlSlice.reducer;
