import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Map} from '@pbe/react-yandex-maps';
import styles from './styles.module.scss';
import {useNavigate} from 'react-router-dom';
import {useLazyGetObjectInMapQuery, useLazyGetPointAllQuery} from './store/monitoring.api';
import {MonitoringFilter, RenderObjPoint, RenderOrderPoint, SwitchStatus} from './components';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {ArrowLeft, HomeIcon, NavigationIcon, ZoomIn, ZoomOut} from '../../assets/svg';
import {themColors} from '../../hooks/useChangeTheme';
import {changePointOrders, initDateKeys} from './store/monitoring.slice';
import classNames from 'classnames';
import {MonitoringFilterItemReq, Point} from './store/types';


export const MonitoringMap = () => {
    const mapRef = useRef<any>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [getDataMap, {data, isFetching}] = useLazyGetObjectInMapQuery();

    const [getAllPoints, {data: orders}] = useLazyGetPointAllQuery();

    const {filter, statuses} = useAppSelector(state => state.monitor);
    const {theme} = useAppSelector(state => state.app);


    const [zoom, setZoom] = useState(10);
    const [ymaps, setYmaps] = useState<any>();
    const [viewOrders, setViewOrders] = useState<Point[]>([]);

    useEffect(() => {
        const params = filter.map(key => {
            if (key.type === 'FObjects') {
                return `${MonitoringFilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
            } else {
                return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
            }
        }).join('&');
        getDataMap({params: params});
        getAllPoints({params})
            .unwrap()
            .then((data) => {
                if (statuses?.length === 0) {
                    setViewOrders([]);
                } else {
                    const newArr = data?.filter(o => statuses.includes(o.status_en)) ?? [];
                    setViewOrders(newArr);
                }
                dispatch(changePointOrders(data));
            });
    }, []);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setZoom(zoom);
        }

    }, [zoom]);


    useEffect(() => {
        if (statuses?.length === 0) {
            setViewOrders([]);
        } else {
            const newArr = orders?.filter(o => statuses.includes(o.status_en)) ?? [];
            setViewOrders(newArr);
        }
    }, [statuses]);

    const handleZoomIn = () => {
        setZoom((prevZoom) => prevZoom < 19 ? prevZoom + 1 : 19); // Максимальный зум - 19
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => prevZoom > 0 ? prevZoom - 1 : 0); // Минимальный зум - 0
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {latitude, longitude} = position.coords;
                    mapRef.current.setCenter([latitude, longitude]);
                },
                (error) => {
                    console.error('Error getting user\'s location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const renderOrder = useMemo(() => {

        return <>{ymaps && viewOrders?.map((item) =>
            <RenderOrderPoint
                key={item.idOrder}
                ymaps={ymaps}
                item={item}/>)}</>;
    }, [viewOrders, ymaps]);

    const applyFilter = () => {
        const params = filter.map(key => {
            if (key.type === 'FObjects') {
                return `${MonitoringFilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
            } else {
                return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
            }
        }).join('&');
        getDataMap({params: params});
        getAllPoints({params})
            .unwrap()
            .then((data) => {
                dispatch(changePointOrders(data));
            });
    };

    const clearFilter = () => {
        const params = initDateKeys.map(key => `${MonitoringFilterItemReq[key.type]}=${key.value}`).join('&');
        getDataMap({params: params});
        getAllPoints({params})
            .unwrap()
            .then((data) => {
                dispatch(changePointOrders(data));
            });
    };
    return (
        <div className={styles.map}>
            <div className={styles.mapHeader}>
                <MonitoringFilter
                    isFixedDate={false}
                    applyFilter={applyFilter}
                    clearFilter={clearFilter}/>
            </div>
            <SwitchStatus position={'fixed'}/>
            <button className={classNames(styles.button, styles.zoomIn)} onClick={handleZoomIn}>
                <ZoomIn color={themColors[theme].color}/>
            </button>
            <button className={classNames(styles.button, styles.zoomOut)} onClick={handleZoomOut}>
                <ZoomOut color={themColors[theme].color}/>
            </button>
            <button className={classNames(styles.button, styles.home)} onClick={() => navigate('/')}>
                <HomeIcon color={themColors[theme].color}/>
                <span className={styles.buttonText}>На главную</span>
            </button>
            <button className={classNames(styles.button, styles.back)} onClick={() => navigate(-1)}>
                <ArrowLeft color={themColors[theme]?.color} width={24} height={24}/>
                <span className={styles.buttonText}>Назад</span>
            </button>
            <button className={classNames(styles.button, styles.navigate)} onClick={getUserLocation}>
                <NavigationIcon color={themColors[theme].color}/>
            </button>

            {
                isFetching
                    ? (
                        <div className={styles.loader}>
                            <p className={styles.loaderText}>идет загрузка...</p>
                        </div>
                    )
                    : (

                        <Map
                            defaultState={{zoom: 10, center: [55.755864, 37.617698]}}
                            width={window.innerWidth}
                            height={window.innerHeight}
                            instanceRef={mapRef}
                            onLoad={ymaps => {
                                setYmaps(ymaps);
                            }}
                        >
                            {data?.map((item) => <RenderObjPoint key={item.id} item={item}/>)}

                            {renderOrder}
                        </Map>
                    )
            }
        </div>
    );
};

