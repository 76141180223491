import React, {useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {clearOrder} from './store/order.slice';
import styles from './styles.module.scss';
import {SwitcherTab} from './components';
import {Common, Delivery, Products, TimePing} from './tabs';

export const OrderDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {order} = useAppSelector(state => state.order);
 
    const [indexTab, setIndexTab] = useState<string>('0');

    const handleBack = () => {
        dispatch(clearOrder());
        navigate(-1);
    };

    const renderTab = () => {
        switch (indexTab) {
            case '0':
                return <Common/>;
            case '1':
                return <Delivery/>;
            case '2':
                return <Products/>;
            case '3':
                return <TimePing/>;
            default:
                return <></>;
        }
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Заказ ${order?.orderInfo.number}`}/>
            <BackButton text={'Назад'} onClick={handleBack}/>
            <div className={styles.container}>
                <SwitcherTab isActiveIndex={indexTab} onTab={setIndexTab}/>
                {renderTab()}
            </div>
        </MainLayout>
    );
};
