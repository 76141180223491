import React from 'react';
import styles from './style.module.scss';
import {ItemToggle} from './ItemToggle';
import classNames from 'classnames';

type ItemRatingType = {
    num: string;
    display: string;
    isExpanded?: boolean;
    setExpanded?: (status: boolean) => void;
    isInner: boolean;
    percent: string;
    col: number;
    mode?: 'dropdown' | 'checkbox'
    handleSelect: () => void;
}

export const ItemRating = (
    {
        num,
        display,
        col,
        percent,
        isExpanded,
        setExpanded,
        handleSelect,
        mode,
        isInner,
    }: ItemRatingType
) => {


    const width = (window.innerWidth - 40);
    const firstItemWidth = isInner ? (width / col) + 70 : (width / 3) + 80;
    const itemWidth = (width - firstItemWidth) / (col - 1);
    return (
        <>
            <div
                className={isInner
                    ? classNames(styles.tableRowInner, styles.tableRowInnerPadding)
                    : styles.tableRowInner}>
                <div
                    style={{width: `${firstItemWidth}px`}}
                    className={styles.tableItem}
                    onClick={handleSelect}
                >
                    {isExpanded !== undefined && mode !== undefined
                        ? <ItemToggle
                            handleOpen={() => setExpanded ? setExpanded(!isExpanded) : null}
                            active={isExpanded}
                            mode={mode}
                        /> : null}
                    <p className={styles.tableTitle}>{num}</p>
                </div>
                <div
                    style={{width: `${itemWidth}px`}}
                    className={classNames(styles.tableItem, styles.tableItemCenter)}
                    onClick={handleSelect}
                >
                    <p className={styles.tableTitle}>{display}</p>
                </div>
                <div
                    style={{width: `${itemWidth}px`}}
                    className={classNames(styles.tableItem, styles.tableItemCenter)}
                    onClick={handleSelect}
                >
                    <p className={styles.tableTitle}>{percent}</p>
                </div>
            </div>
        </>
    );
};
