import {api} from '../../../store/api';
import {Financial, FinancialFile, IColor, ResponseFinancialList, TableBodyBudget, TableHeadBudget} from './types';
import {CONTENT_TYPE_URLENCODED} from '../../../utils/constants';
import {IPermission, Option} from '../../../store/types';

export const financialApi = api.injectEndpoints({
    endpoints: build => ({
        getFinancials: build.query<any, { params: string }>({
            query: ({params}) => ({
                url: `/FinancialApplications/p_AppListFin.php?${params}`,
                method: 'GET'
            })
        }),
        getFinancialCount: build.mutation<{ status: boolean, quantity: number }, { path: string }>({
            query: ({path}) => ({
                url: path,
                method: 'GET',
                keepUnusedDataFor: 5,
            }),
            transformResponse: ({quantity}) => {
                return quantity;
            }
        }),
        getFinancialList: build.query<ResponseFinancialList, { path: string }>({
            query: ({path}) => ({
                url: path,
                method: 'GET',
                keepUnusedDataFor: 5,
            }),
            transformResponse: ({listFinApp}) => {
                return {
                    listFinApp
                };
            }
        }),
        getColorsList: build.query<{ list: Option[] }, void>({
            query: () => ({
                url: '/listData/p_ColorList.php',
                method: 'GET'
            }),
            transformResponse: ({list}) => {
                return {
                    list: list.map((i: IColor) => ({
                        value: String(i.ID),
                        label: i.fullName
                    }))
                };
            }
        }),
        handleFinancialAction: build.mutation<{ finApp: Financial, message?: string, status: boolean }, { body: string }>({
            query: ({body}) => ({
                url: '/FinancialApplications/r_AppFin.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED
                }
            })
        }),
        getFinancialFiles: build.query<{ filesList: FinancialFile[] }, { params: string }>({
            query: ({params}) => ({
                url: `/FinancialApplications/files.php?${params}`
            })
        }),
        getPermissionFinancial: build.query<{ permission: IPermission }, { ID_App: number }>({
            query: () => ({
                url: '/FinancialApplications/finAppPermission.php',
                method: 'GET'
            }),
            transformResponse: ({permission}) => {
                return {permission};
            }
        }),
        getBudget: build.query<{ tableHead: TableHeadBudget[], tableBody: TableBodyBudget }, { ID_Obj: number }>({
            query: ({ID_Obj}) => ({
                url: `/FinancialApplications/budgetTable.php?ID_Obj=${ID_Obj}`
            }),
            transformResponse: ({tableHead, tableBody}) => {
                return {tableHead, tableBody};
            }
        }),
        financialConfirm: build.mutation<void, { body: any }>({
            query: ({body}) => ({
                url: '/FinancialApplications/finAppConfirmation.php',
                method: 'POST',
                body
            })
        }),
        getFinancialById: build.query<Financial, { ID_App: number }>({
            query: ({ID_App}) => ({
                url: `/FinancialApplications/p_AppListFin.php?FApps=${ID_App}`,
                method: 'GET'
            }),
            transformResponse: ({listFinApp}) => {
                return listFinApp[0];
            }
        }),
        getCurrentFileList: build.query({
            query: () => ({
                url: `/FinancialApplications/files.php?ID_Tab=17789&typeFile=100`
            })
        })
    }), overrideExisting: true
});

export const {
    useGetFinancialCountMutation,
    useLazyGetFinancialListQuery,
    useGetColorsListQuery,
    useHandleFinancialActionMutation,
    useGetFinancialFilesQuery,
    useGetPermissionFinancialQuery,
    useGetBudgetQuery,
    useFinancialConfirmMutation,
    useLazyGetFinancialByIdQuery
} = financialApi;
