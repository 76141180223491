import {api} from '../../../store/api';
import {DeliveryOrder} from './types';

const courierApi = api.injectEndpoints({
    endpoints: build => ({
        getTodayOrders: build.query<DeliveryOrder[], { params: string }>({
            query: ({params}) => ({
                url: `/workShift/ordersMap?${params}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({listOrders}) => {
                return listOrders;
            }
        })
    }), overrideExisting: true
});

export const {useLazyGetTodayOrdersQuery} = courierApi;
