import React from 'react';
import styles from '../style.module.scss';
import {useCollapse} from 'react-collapsed';
import classNames from 'classnames';

interface AnswersProps {
    Answer: string | null;
    provider: string;
}

type AnswerJson = {
    date_created: string;
    date_updated: string;
    files: []
    id: number;
    text: string;
    ticket_id: number;
    user_email: string;
    user_id: number;
    user_lastname: string;
    user_name: string;
}

export const Answers = ({Answer, provider}: AnswersProps) => {
    const {getCollapseProps, getToggleProps, isExpanded} = useCollapse();
    if (!Answer) {
        return <></>;
    }
    const answers: AnswerJson[] | string = provider === 'КЦ' ? JSON.parse(Answer)?.reverse() : Answer;

    if (typeof answers === 'string') {
        return (
            <div className={styles.cardComment}>
                <p className={styles.cardCommentText}>{answers}</p>
            </div>
        );
    }

    const membersIds = [...new Set(answers.map(i => i.user_id))];


    return <div className={styles.cardComment}>
        <div {...getToggleProps()} className={styles.collapseButton}>
            <span className={styles.cardCommentText}>Ответы</span>
            <span
                className={
                    isExpanded
                        ? classNames(styles.arrowUp, styles.arrowUpUpped)
                        : styles.arrowUp}/>
        </div>
        <ul className={styles.cardCommentList}  {...getCollapseProps()}>
            {answers.map(item => {
                const isFrom = membersIds[0];
                return (
                    <li
                        key={item.id}
                        className={isFrom === item.user_id
                            ? styles.cardCommentListItem
                            : classNames(styles.cardCommentListItem, styles.cardCommentListItemTo)}
                    >
                        <div className={styles.answer}>
                            <div className={styles.answerRow}>
                                <span className={styles.answerName}>{item.user_name} {item.user_lastname}</span>
                                <span className={styles.answerTime}>{item.date_created}</span>
                            </div>
                            <p className={styles.answerText}>{item.text}</p>
                        </div>
                    </li>
                );
            })}
        </ul>
    </div>;
};
