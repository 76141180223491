import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {getObjectByIdForm} from '../../helpers';
import {useLazyGetTaskListQuery} from './store/taskList.api';
import {TaskItem} from './components';
import styles from './style.module.scss';
import Footer from '../../components/footer';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {ListMenu} from '../home/store/types';
import {Task} from './store/types';
import 'dayjs/locale/ru';
import {CARD_HEIGHT, parseQueryString} from './helpers';
import {TaskFilter} from '../../components/taskFilter';
import {FilterItemReq} from '../../components/taskFilter/store/type';
import {dateKeys, setClearFilter, setFilter} from '../../components/taskFilter/store/filter.slice';
import {ScrollUp} from '../../components/scrollUp';
import {TaskLocationState} from '../taskCreate/store/types';
import {useViewedTaskMutation} from '../taskCreate/store/task.api';
import AppLoader from '../../components/appLoader';


export const TaskList = () => {
    const dispatch = useAppDispatch();
    const {idForm} = useParams<{ idForm: string }>();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const {keys} = useAppSelector(state => state.filter);
    const {data} = useGetAllQuestionsQuery();
    const [viewedTask] = useViewedTaskMutation();
    let taskVariable: ListMenu | null = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));

    const [getTaskList, {data: list, isFetching}] = useLazyGetTaskListQuery();

    useEffect(() => {
        if (taskVariable) {

            const params = keys.map(key => `${FilterItemReq[key.type]}=${key.value}`).join('&');
            getList(params);
        }
    }, [taskVariable]);

    function getList(params: string) {

        getTaskList({path: `${taskVariable?.file}&${params}`});
    }

    useEffect(() => {
        const {currentIndex} = parseQueryString(searchParams);

        if (currentIndex > 0) {
            const mainContainer = document.getElementById('main-container');
            if (mainContainer) {
                setTimeout(() => {
                    mainContainer.scrollTo({
                        top: currentIndex * CARD_HEIGHT,
                        behavior: 'smooth'
                    });
                }, 500);
            }
        }
    }, []);

    const handleSetIndex = (value: string, type: 'index') => {
        const updatedParams = new URLSearchParams(searchParams);
        if (updatedParams.has(type)) {
            updatedParams.delete(type);
        }
        updatedParams.append(type, value);
        setSearchParams(updatedParams);
    };

    const applyFilter = (isInit: boolean) => {
        if (isInit) {
            dispatch(setClearFilter());
        }
        const params = isInit
            ? dateKeys.map(key => `${FilterItemReq[key.type]}=${key.value}`).join('&')
            : keys.map(key => `${FilterItemReq[key.type]}=${key.value}`).join('&');

        getList(params);
    };


    const onSearch = (text: string) => {
        if (keys.some(k => k.type === 'Search')) {
            const newKeys = keys.map(k => k.type === 'Search' ? {...k, value: text} : k);
            dispatch(setFilter(newKeys));
            const params = newKeys.map(key => `${FilterItemReq[key.type]}=${key.value}`).join('&');
            getList(params);
        } else {
            dispatch(setFilter([...keys, {type: 'Search', value: text}]));
            const params = [...keys, {
                type: 'Search',
                value: text
            }].map(key => `${FilterItemReq[key.type]}=${key.value}`).join('&');
            getList(params);
        }
    };

    const handleClick = (item: Task) => {
        const currentIndex = list?.listTask.findIndex(i => i.ID === item.ID);
        handleSetIndex(String(currentIndex), 'index');
        viewedTask({body: `ID_App=${item.ID}`});
        const state: TaskLocationState = {
            idForm
        };
        taskVariable = null;
        navigate(`/task-create?ID_App=${item.ID}`, {state});
    };


    return (
        <MainLayout>
            <Header breadCrumbs={'Задачи - Список'}/>
            <BackButton text={'Назад в задачи'} onClick={() => {
                navigate(`/task-menu/${taskVariable?.idParent}`);
            }}/>
            <TaskFilter
                keys={keys}
                useInputSearch={true}
                applyFilter={applyFilter}
                onSearch={text => onSearch(text)}
            />
            <div className={styles.cardList}>
                {isFetching ? <AppLoader isFull={false}/>
                    : list?.listTask.map(item => {
                        return <TaskItem key={item.ID} item={item} handleClick={handleClick}/>;
                    })}

                <ScrollUp/>
            </div>
            <Footer/>
        </MainLayout>
    );
};

