import React from 'react';
import styles from '../styles.module.scss';

type ParagraphProps = {
    text: string;
    subtext?: string;
    isBold: boolean;
}

export const Paragraph = ({text, subtext, isBold}: ParagraphProps) => {
    return (
        <p className={isBold ? styles.tabTextBold : styles.tabText}>
            {subtext ? <span className={styles.tabSubtext}>{subtext}</span> : ''}
            {text}</p>
    );
};
