import {useEffect, useRef} from 'react';
import JsSIP from 'jssip';

export const useCall = () => {
    const socket = useRef<any>(null);
    const ua = useRef<any>(null);

    const domain = 'vats982344.megapbx.ru';
    const login = 'victortih';

    const password = 'Fkumateh1';

    useEffect(() => {
        // Создание WebSocket-соединения
        //JsSIP.debug.enable('JsSIP:*');
        socket.current = new JsSIP.WebSocketInterface(`wss://${domain}/ws`);

        if (socket.current) {


            // Создание UserAgent
            const configuration = {
                sockets: [socket.current],
                uri: `${login}@${domain}:5060`,
                password: password,
                // Дополнительные настройки, если необходимо
            };

            let remoteAudio = new window.Audio();
            remoteAudio.autoplay = true;

            ua.current = new JsSIP.UA(configuration);

            // Обработка событий
            ua.current.on('registered', () => {
                console.log('Registered successfully');
            });

            ua.current.on('registrationFailed', (e: any) => {
                console.error('Registration failed:', e.cause);
            });

            ua.current.on('newRTCSession', (data: any) => {
                const session = data.session;

                // Обработка входящего вызова
                if (session.direction === 'incoming') {

                    session.answer(); // Ответить на вызов
                }
            });


            // Инициализация UserAgent
            ua.current.start();
        }

        return () => {
            // Очистка при размонтировании компонента
            ua.current.stop();
        };
    }, []);

    // Функция для совершения звонка
    const makeCall = (target: any) => {
        const session = ua.current.call(`${target}@${domain}`);
        session.on('accepted', () => {
            console.log('Call accepted');
        });

        session.on('ended', () => {
            console.log('Call ended');
        });
    };

    return {
        makeCall,
    };
};
