import React from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {useNavigate} from 'react-router-dom';
import {useLazyOrderQueryTodayQuery} from './store/monitoring.api';
import {MonitoringFilter, OrderTodayItem} from './components';
import {useMonitoringControl} from '../../hooks';
import AppLoader from '../../components/appLoader';

export const MonitoringLoad = () => {
    const navigate = useNavigate();
    const [getOrderQueryToday, {data: orderQuery, isLoading}] = useLazyOrderQueryTodayQuery();
    const {applyFilter, clearFilter} = useMonitoringControl({

        onFetch: ({params}) => {
            getOrderQueryToday({params});
        }
    });

    return (
        <MainLayout>
            <Header breadCrumbs={'Мониторинг - Сегодня'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={true}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {isLoading
                ? <AppLoader isFull={false}/> : (
                    <>
                        <div className={styles.listOrders}>
                            <OrderTodayItem items={orderQuery?.first ?? []}/>
                            <div className={styles.separatorM20}/>
                            <OrderTodayItem items={orderQuery?.second ?? []}/>
                            <div className={styles.separatorM20}/>
                            <OrderTodayItem items={orderQuery?.third ?? []}/>
                            <div className={styles.separatorM20}/>
                            <OrderTodayItem items={orderQuery?.fourth ?? []}/>
                            <div className={styles.separatorM20}/>
                            <OrderTodayItem items={orderQuery?.fifth ?? []}/>
                        </div>
                    </>
                )}

            <Footer/>
        </MainLayout>
    );
};
