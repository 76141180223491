import {combineReducers} from '@reduxjs/toolkit';
import {api, apiPush} from './api';
import auth from '../screens/auth/store/auth.slice';
import app from './app.slice';
import quiz from '../screens/quiz/store/quiz.slice';
import camera from '../screens/camera/store/camera.slice';
import taskMenu from '../screens/taskTypes/store/taskTypes.slice';
import financialMenu from '../screens/financial/state/financial.slice';
import filter from '../components/taskFilter/store/filter.slice';
import financialFilter from '../components/financialFilter/store/financial.filter.slice';
import chat from '../components/chat/store/chat.slice';
import report from '../screens/report/store/report.slice';
import taskSwitch from '../screens/taskCreate/store/task.slice';
import inventory from '../screens/inventory/store/inventory.slice';
import actions from '../screens/actionList/store/action.slice';
import photoReport from '../screens/photoReport/store/photoReport.slice';
import employee from '../screens/selectEmployee/store/employee.slice';
import call from '../screens/phoneCall/store/call.slice';
import table from '../screens/tableView/store/table.slice';
import monitor from '../screens/monitoring/store/monitoring.slice';
import order from '../screens/monitoringOrder/store/order.slice';
import timeControl from '../screens/timeControl/store/timeControl.slice';
import reviews from '../screens/reviews/store/review.slice';

export const rootReducer = combineReducers({
    auth,
    app,
    quiz,
    camera,
    taskMenu,
    financialMenu,
    filter,
    financialFilter,
    chat,
    report,
    taskSwitch,
    inventory,
    actions,
    photoReport,
    employee,
    call,
    table,
    monitor,
    order,
    timeControl,
    reviews,
    [apiPush.reducerPath]: apiPush.reducer,
    [api.reducerPath]: api.reducer,
});
