import React from 'react';
import styles from '../styles.module.scss';
import {useAppSelector} from '../../../hooks/app.hooks';
import {Paragraph} from '../components';

const defaultImage = require('../../../assets/images/default-image.png');

//Список блюд с количеством и ценами в этом заказе
export const Products = () => {
    const {order} = useAppSelector(state => state.order);

    if (!order) {
        return <></>;
    }
    return (
        <div className={styles.tab}>
            {order.orderContent.map(item => {
                return (
                    <div className={styles.tabCard}>
                        <div className={styles.tabImageBox}>
                            <img src={defaultImage} alt={'default-image'}/>
                        </div>
                        <div className={styles.tabBox}>
                            <p className={styles.tabTitle}>{item.product.name}</p>
                            <div className={styles.tabSeparator}/>
                            <Paragraph text={String(item.amount)} subtext={'Количество:'} isBold={false}/>
                            <Paragraph text={String(item.resultSum)} subtext={'Стоимость:'} isBold={false}/>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
