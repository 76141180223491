import React from 'react';
import {useCollapse} from 'react-collapsed';
import styles from './style.module.scss';
import {ReviewOrs} from '../../store/types';
import {ItemOpt} from './ItemOpt';
import {useAppDispatch, useAppSelector} from '../../../../hooks/app.hooks';
import {useNavigate} from 'react-router-dom';
import {setReviewsFilter} from '../../store/review.slice';

interface TableOpsAndTuRowProps {
    col: number;
    item: ReviewOrs;
}

export const TableOpsAndTuRow = (
    {
        col,
        item
    }: TableOpsAndTuRowProps
) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {filter} = useAppSelector(state => state?.reviews);

    const {getCollapseProps, setExpanded, isExpanded} = useCollapse();

    const handlePress = (type: 'FEmplOPS' | 'FEmplTU', id: number) => {
        if (Math.sign(id) === 1) {
            const newKeys = [
                ...filter,
                {type, value: String(id)},
            ];

            dispatch(setReviewsFilter(newKeys));
        }
        navigate('/review-objects');
    };
    return (
        <>
            <div className={styles.tableRowCollapse}>
                <ItemOpt
                    name={item.name}
                    col={col}
                    avgRating={item.avgRating?.toFixed(1)}
                    qntNegative={item.qntNegative?.toFixed(1)}
                    negativePercent={item.negativePercent?.toFixed(1)}
                    isInner={false}
                    mode={item.name === 'ВСЕГО' ? undefined : 'dropdown'}
                    isExpanded={isExpanded}
                    setExpanded={setExpanded}
                    handleSelect={() => handlePress('FEmplOPS', item.id)}
                />
                <div className={styles.tableRowBody} {...getCollapseProps()}>
                    {item.tu.map((tu) => {
                        return (
                            <ItemOpt
                                key={tu.id}
                                col={col}
                                name={tu.name}
                                avgRating={tu.avgRating?.toFixed(1)}
                                qntNegative={tu.qntNegative?.toFixed(1)}
                                negativePercent={tu.negativePercent?.toFixed(1)}
                                isInner={true}
                                mode={'dropdown'}
                                handleSelect={() => handlePress('FEmplTU', tu.id)}
                            />
                        );
                    })}
                </div>
            </div>

        </>
    );

    // return (
    //     <>
    //         <div
    //             className={index % 2 !== 0 ? styles.tableRowCollapse : classNames(styles.tableRowCollapse, styles.tableRowCollapseGrey)}
    //             {...getToggleProps({
    //                 onClick: () => setExpanded((prevExpanded) => !prevExpanded),
    //             })}>
    //             <Item isFirst={true} column={4} isExpanded={isExpanded} setExpanded={setExpanded} text={item.name}/>
    //             <Item isFirst={false} column={4} isExpanded={isExpanded} setExpanded={setExpanded}
    //                   text={item.avgRating?.toFixed(1)}/>
    //             <Item isFirst={false} column={4} isExpanded={isExpanded} setExpanded={setExpanded}
    //                   text={item.qntNegative?.toFixed(1)}/>
    //             <Item isFirst={false} column={4} isExpanded={isExpanded} setExpanded={setExpanded}
    //                   text={`${item.negativePercent?.toFixed(1)}%`}/>
    //         </div>
    //         <div {...getCollapseProps()}>
    //
    //             {item.tu.map(tu => {
    //                 return (
    //                     <div key={tu.id} className={styles.tableRowCollapseAdditional}>
    //                         <Item column={4} isFirst={true} text={tu.name}/>
    //                         <Item column={4} isFirst={false} text={tu.avgRating?.toFixed(1)}/>
    //                         <Item column={4} isFirst={false} text={tu.qntNegative?.toFixed(1)}/>
    //                         <Item column={4} isFirst={false} text={tu.negativePercent?.toFixed(1)}/>
    //                     </div>
    //                 );
    //             })}
    //
    //         </div>
    //     </>
    // );
};
