import React, {ReactNode} from 'react';
import styles from './style.module.scss';
import {useAppSelector} from '../../hooks/app.hooks';
import {useLocation, useNavigate} from 'react-router-dom';
import {NotificationIcon} from '../../assets/svg';
import {themColors} from '../../hooks/useChangeTheme';


interface HeaderProps {
    breadCrumbs?: string;
    innerText?: ReactNode;
}

const Header = ({breadCrumbs, innerText}: HeaderProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {name} = useAppSelector(state => state.auth.user);
    const {theme} = useAppSelector(state => state.app);

    return (
        <div className={styles.header}>
            <div className={styles.content}>
                {
                    location.pathname === '/'
                        ? <>{name.length > 0
                            ? <p className={styles.name}>{`Привет, ${name}!`}</p>
                            : null}
                            <p className={styles.appName}>Демо доступ активирован</p>
                        </>
                        : breadCrumbs
                            ? <p>{breadCrumbs}</p>
                            : innerText
                }

            </div>
            <div className={styles.notification} onClick={() => navigate('/action-list')}>
                <NotificationIcon color={themColors[theme]?.color ?? '#000000'}/>
            </div>
        </div>
    );
};

export default Header;
