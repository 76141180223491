import React, {useCallback, useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {useNavigate} from 'react-router-dom';
import {BackButton} from '../../components/backButton';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {
    useLazyGetChartRatingsQuery,
    useLazyGetOrsAndTuQuery,
    useLazyGetTableSourceQuery,
    useLazyGetTableTagQuery
} from './store/review.api';
import {Chart, Filter} from './components';
import styles from './style.module.scss';
import AppLoader from '../../components/appLoader';
import {toast} from 'react-toastify';
import {dateKeys, setReviewsFilter} from './store/review.slice';
import {ReviewBody, ReviewOrs} from './store/types';
import {Table} from './components/table';

export const RATING = ['1', '2', '3', '4', '5'];

export const ReviewCommon = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {filter} = useAppSelector(state => state?.reviews);

    const [getChart, {data}] = useLazyGetChartRatingsQuery();
    const [getOpsAndTu, {data: otsTu}] = useLazyGetOrsAndTuQuery();
    const [getTableSource, {data: table}] = useLazyGetTableSourceQuery();
    const [getTags, {data: tags}] = useLazyGetTableTagQuery();

    const [loader, setLoader] = useState<boolean>(true);


    useEffect(() => {
        fetchData();
    }, []);

    const opt = data?.res.map(i => ({name: i.name, value: String(i.value)})) ?? [];

    const applyFilter = useCallback((newKeys?: any[]) => {
        fetchData(newKeys);
    }, [filter]);

    const fetchData = (newKeys?: any) => {
        const params = newKeys
            ? newKeys.map((key: any) => `${key.type}=${key.value}`).join('&')
            : filter.map(key => `${key.type}=${key.value}`).join('&');
        setLoader(true);

        Promise.all([
            getChart({params}),
            getOpsAndTu({params}),
            getTableSource({params}),
            getTags({params})
        ]).then(() => {
            setLoader(false);

        }).catch(() => {
            toast.error('Ошибка загрузки данных, попробуйте позже');
            setLoader(false);
        });
    };

    const clearFilter = () => {
        dispatch(setReviewsFilter(dateKeys));
        let params = dateKeys?.map(item => `${item.type}=${item.value}`).join('&') ?? '';
        Promise.all([
            getChart({params}),
            getOpsAndTu({params}),
            getTableSource({params}),
            getTags({params})
        ]).then(() => {
            setLoader(false);
        }).catch(() => {
            toast.error('Ошибка загрузки данных, попробуйте позже');
            setLoader(false);
        });
    };


    return (
        <MainLayout>
            <Header breadCrumbs={'Cводные итоги'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.box}>
                <Filter clearFilter={clearFilter} applyFilter={applyFilter}/>
            </div>
            {
                loader
                    ? <AppLoader isFull={false}/>
                    : (
                        <>
                            <div className={styles.box}>
                                <p className={styles.boxLabel}>Оценки</p>
                                <Chart
                                    chart={opt}
                                    handleClickByLegend={() => {
                                    }}
                                />
                            </div>
                            <div className={styles.box}>
                                <p className={styles.boxLabel}>Опс и Ту</p>
                                <Table
                                    <ReviewOrs>
                                    headLabels={['Опс', 'Ср.Оц', 'Нег..', '% Нег..']}
                                    body={otsTu ?? []}
                                />
                            </div>
                            <div className={styles.box}>
                                <p className={styles.boxLabel}>Источники</p>
                                <Table
                                    <ReviewBody>
                                    headLabels={['Источники оценки', 'Всего', '']}
                                    body={table?.body as ReviewBody[] ?? []}
                                />
                            </div>
                            <div className={styles.box}>
                                <p className={styles.boxLabel}>Теги</p>
                                <Table
                                    <ReviewBody>
                                    headLabels={['По тегам', 'Всего', '']}
                                    body={tags?.body as ReviewBody[] ?? []}
                                />
                            </div>
                        </>
                    )
            }
        </MainLayout>
    );
};
