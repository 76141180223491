import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {useGetPdfQuery} from '../quiz/store/quiz.api';
import AppLoader from '../../components/appLoader';
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import {formatNumber} from '../../helpers';
import classNames from 'classnames';
import {Controls, SortControl} from './components';
import {useShare} from '../../hooks';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {setSortColumn} from './store/table.slice';
import {config} from './config';

interface ResHead {
    key: string;
    name: string;
    type: string;
    width: number;
}

interface ResBody {
    ID_Obj: number;
    ObjFormat: number | null;
    ParentCode: string;
    ObjName: string;
    avgCheck: number;
    breakEven: number;
    checksCnt: number;
    pastInterval0: number;
    pastInterval1: number;
    pastInterval2: number;
    pastInterval3: number;
    paySum: number;
    plan: number;
    time: string | null;
}

function formatText(text: string | number | null, key: string): string {
    if (text === null && text === undefined) {
        return '--';
    }
    if (typeof text === 'string') {
        return text;
    }

    if (key === 'checksCnt') {
        return formatNumber(text ?? 0);
    }
    return formatNumber(text ?? 0).split('.')[0];
}

function sortByKey<T>(arr: T[], key: keyof T, direction: 'asc' | 'desc'): T[] {

    return arr.slice().sort((a: T, b: T) => {
        if (direction === 'asc') {
            if (a[key] > b[key]) {
                return -1;
            }
            if (a[key] < b[key]) {
                return 1;
            }
        }
        if (direction === 'desc') {
            if (a[key] > b[key]) {
                return 1;
            }
            if (a[key] < b[key]) {
                return -1;
            }
        }

        return 0;
    });
}

const EXCLUDE_KEYS: string[] = []; // ['ID_Obj'];

export const TableView = () => {
    const location: Location = useLocation();
    const {state} = location as Location & { state: { tableName: string, path: string, useField: string, screenViewName: string } };
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {data, isLoading} = useGetPdfQuery({path: state?.path}, {
        skip: !state?.path
    });

    const {sortColumn} = useAppSelector(state => state.table);
    const {isFetching, handleShare} = useShare({path: state?.path, screenViewName: state?.screenViewName});
    const [positionIndex, setPosIndex] = useState<number>(0);

    const [table, setTable] = useState<{ resBody: ResBody[], resHead: ResHead[] } | undefined>();

    useEffect(() => {
        if (data) {
            if (state?.useField) {
                const resHead = data.resHead.filter((item: ResHead) => config[state?.useField].keys.includes(item.key));
                setTable({resBody: data.resBody, resHead});
            } else {
                const resHead = data.resHead; //.filter((item: ResHead) => config['main'].keys.includes(item.key));
                setTable({resBody: data.resBody, resHead});
            }
        }
    }, [data]);

    const handleSort = (key: string, direction: 'asc' | 'desc') => {
        dispatch(setSortColumn({key, direction: direction === 'asc' ? 'desc' : 'asc'}));
    };

    useEffect(() => {
        if (table && table.resBody) {
            const sortArray = sortByKey(table?.resBody as ResBody[], sortColumn.key as keyof ResBody, sortColumn.direction);
            setTable({resBody: sortArray, resHead: table?.resHead as ResHead[]});
        }
    }, [sortColumn]);


    const getStylesWidth = () => {

        const count = state?.useField ? config[state?.useField].keys.length : data?.resHead?.length;
        const widthScreen = window.innerWidth;

        if (widthScreen > 600) {
            return {width: `${984}px`};
        } else if (count <= 6) {
            return {width: `${window.innerWidth - 40}px`};
        } else {
            return {};
        }
    };

    const isLessFive = (table?.resHead?.length as number) < 4;

    return (
        <MainLayout>
            <Header breadCrumbs={state?.tableName ? state.tableName : 'Таблица'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>

            {isLoading
                ? <AppLoader isFull={false}/>
                : table?.resHead?.length === 0
                    ? (<div className={styles.empty}>
                        <p className={styles.emptyTitle}>В разработке</p>
                        <p className={styles.emptyText}>отчет появится при следующих обновлениях</p>
                    </div>)
                    : (
                        <div className={styles.wrapper}>
                            <TransformWrapper
                                disabled={positionIndex === 0}
                            >
                                <>
                                    <Controls
                                        handleShare={handleShare}
                                        loader={isFetching}
                                        positionIndex={positionIndex}
                                        setPosIndex={setPosIndex}
                                    />
                                    <TransformComponent
                                        contentStyle={getStylesWidth()}
                                        wrapperStyle={getStylesWidth()}>

                                        <div className={styles.table}>
                                            <div className={styles.tableHeader}>
                                                {table?.resHead?.map((item: ResHead, index: number) => {

                                                    if (item.key === 'ID_Obj') {
                                                        return (
                                                            <div key={item.key}
                                                                 className={isLessFive ? styles.tableHeadLessFive : styles.tableHead}>
                                                                <div className={styles.tableHeadInner}>
                                                                <span
                                                                    className={styles.tableHeadText}>№</span>
                                                                </div>
                                                            </div>
                                                        );
                                                    }

                                                    return (
                                                        <div key={item.key}
                                                             className={isLessFive ? styles.tableHeadLessFive : styles.tableHead}>
                                                            <div className={styles.tableHeadInner}>
                                                                <span
                                                                    className={styles.tableHeadText}>{item.name}</span>
                                                            </div>
                                                            {index > 2
                                                                ? <SortControl
                                                                    isActive={sortColumn.key === item.key}
                                                                    direction={sortColumn?.direction}
                                                                    onPress={(isActive) => handleSort(item.key, isActive)}
                                                                />
                                                                : null}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {table?.resBody?.map((item: ResBody, index) => {

                                                return (
                                                    <div key={index} className={styles.tableRow}>
                                                        {table?.resHead?.map((h: ResHead, idx) => {
                                                            const text = EXCLUDE_KEYS.includes(h.key) ? null : item[`${h.key}` as keyof ResBody];

                                                            if (text === null) {
                                                                return (
                                                                    <div key={h.key}
                                                                         className={isLessFive ? styles.tableRowItemLessFive : styles.tableRowItem}>
                                                                <span
                                                                    className={
                                                                        styles.tableRowText
                                                                    }>--</span>
                                                                    </div>
                                                                );
                                                            }

                                                            if (h.key === 'ID_Obj') {
                                                                return (
                                                                    <div key={h.key}
                                                                         className={isLessFive ? styles.tableRowItemLessFive : styles.tableRowItem}>
                                                                <span
                                                                    className={
                                                                        styles.tableRowText
                                                                    }>{index}</span>
                                                                    </div>
                                                                );
                                                            }


                                                            return (
                                                                <div key={h.key} className={typeof text === 'number' ?
                                                                    classNames(isLessFive ? styles.tableRowItemLessFive : styles.tableRowItem, styles.tableRowItemNum)
                                                                    : isLessFive ? styles.tableRowItemLessFive : styles.tableRowItem}>
                                                                <span
                                                                    className={
                                                                        styles.tableRowText
                                                                    }>
                                                                    {formatText(text, h.key)}
                                                                </span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>);
                                            })}
                                        </div>

                                    </TransformComponent>
                                </>

                            </TransformWrapper>
                        </div>
                    )
            }

        </MainLayout>

    );
};
