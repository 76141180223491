import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {ReviewFilter, ReviewObjectLocation} from './store/types';
import {useLazyGetTableByObjQuery} from './store/review.api';
import AppLoader from '../../components/appLoader';
import styles from './style.module.scss';
import {useAppSelector} from '../../hooks/app.hooks';
import {Card} from './components';
import {ScrollUp} from '../../components/scrollUp';

function getParamsTableObjReview(filter: ReviewFilter[], state: ReviewObjectLocation): string {
    let params = filter.map(item => `${item.type}=${item.value}`).join('&');
    if (state.provider) {
        params = params + `&FRatings=${state.grade}&provider=${state.provider}`;
    }
    if (state.tag) {
        params = params + `&FRatings=${state.grade}&tag=${state.tag}`;
    }
    if (state.FObjects) {
        params = `${params}&FObjects=${state.FObjects}`;
    }

    return params;
}

export const ReviewObjList = () => {
    const navigate = useNavigate();
    const location: Location = useLocation();
    const {state} = location as Location & { state: ReviewObjectLocation };
    const {filter} = useAppSelector(state => state.reviews);

    const [getList, {data, isLoading}] = useLazyGetTableByObjQuery();

    useEffect(() => {
        if (state) {
            const params = getParamsTableObjReview(filter, state);
            getList({params});
        }
    }, []);
    return (
        <MainLayout>
            <Header breadCrumbs={'Отзывы по объекту'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <div className={styles.list}>
                            <p className={styles.listName}>
                                {`Отобрано отзывов - ${data?.body.length}`}
                            </p>
                            {data?.body.map(item => {
                                return (
                                    <div key={item.ID} className={styles.listItem}>
                                        <Card item={item}/>
                                    </div>
                                );
                            })}
                            <ScrollUp/>
                        </div>
                    )
            }
        </MainLayout>
    );
};
