import React from 'react';
import styles from './style.module.scss';
import {ReviewBody, ReviewOrs} from '../../store/types';
import {TableRow} from './TableRow';
import {TableOpsAndTuRow} from './TableOpsAndTuRow';

interface TableProps<T> {
    headLabels: string[];
    body: T[];
}

export const Table = <T extends ReviewBody | ReviewOrs, >(
    {
        headLabels,
        body
    }: TableProps<T>
) => {

    const width = (window.innerWidth - 40);
    const firstItemWidth = (width / headLabels.length) + 80;
    const itemWidth = (width - firstItemWidth) / (headLabels.length - 1);

    return (
        <div className={styles.table}>
            <div className={styles.tableHead}>
                {headLabels.map((item, idx) =>
                    <div
                        key={idx}
                        className={styles.tableItem}
                        style={{width: idx === 0 ? `${firstItemWidth}px` : `${itemWidth}px`}}
                    >
                        <p className={styles.tableTitle}>{item}</p>
                    </div>
                )}
            </div>
            <>
                {body.map((row, rowIndex) => {
                    if ('tu' in row) {
                        return <TableOpsAndTuRow key={rowIndex} col={headLabels.length} item={row}/>;
                    } else {
                        return (
                            <TableRow
                                col={headLabels.length}
                                key={rowIndex}
                                row={row}/>
                        );
                    }
                })}
            </>
        </div>
    );
};
