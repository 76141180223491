import React, {useMemo} from 'react';
import {IObject} from '../store/types';
import styles from '../style.module.scss';
import {SimpleInput} from '../../../fields/simpleInput';
import classNames from 'classnames';
import {Map, Placemark, YMaps} from '@pbe/react-yandex-maps';
import {useOrientation} from '../../../hooks';

interface InfoTabProps {
    object: IObject | undefined;
}

export const InfoTab = ({object}: InfoTabProps) => {
    const {orientation} = useOrientation();
    const center: number[] = object?.Latitude && object.Longitude ? [object?.Latitude, object?.Longitude] : [55.684758, 37.738521];

    const loadMap = () => {
        const classMapTooltip = document.querySelector('.ymaps-2-1-79-map-copyrights-promo');

        if (classMapTooltip instanceof HTMLElement) {
            classMapTooltip.style.display = 'block';
        }
    };

    const renderMap = useMemo(() => {
        return (
            <>
                <YMaps
                    query={
                        {
                            lang: 'ru_RU',
                            apikey: '0adbb008-3071-4219-9486-f3377b7a8089'
                        }
                    }>
                    <Map
                        defaultState={{center: center, zoom: 16}}
                        width={window.innerWidth - 40}
                        height={orientation === 'portrait' ? 150 : 300}
                        onLoad={() => {
                            loadMap();
                        }}
                    >
                        <Placemark geometry={center}/>
                    </Map>
                </YMaps>
            </>
        );
    }, [orientation]);
    return (
        <>
            <div className={styles.box}>
                <div className={styles.boxLabel}>
                    <span className={styles.label}>ЮЛ:</span>
                </div>
                <div className={styles.boxInput}>
                    <SimpleInput type={'text'} size={'sm'} readonly={true} value={object?.ParentName as string}/>
                </div>
            </div>
            <div className={styles.box}>
                <div className={styles.boxLabel}>
                    <span className={styles.label}>ОПС:</span>
                </div>
                <div className={styles.boxInput}>
                    <SimpleInput type={'text'} size={'sm'} readonly={true} value={object?.OpsName as string}/>
                </div>
            </div>
            <div className={styles.box}>
                <div className={styles.boxLabel}>
                    <span className={styles.label}>ТУ:</span>
                </div>
                <div className={styles.boxInput}>
                    <SimpleInput type={'text'} size={'sm'} readonly={true} value={object?.TuName as string}/>
                </div>
            </div>
            <div className={styles.box}>
                <div className={styles.boxLabel}>
                    <span className={styles.label}>Упр:</span>
                </div>
                <div className={styles.boxInput}>
                    <SimpleInput type={'text'} size={'sm'} readonly={true} value={object?.UprName as string}/>
                </div>
            </div>
            <div className={styles.box}>
                <div className={styles.boxLabel}>
                    <span className={styles.label}>email:</span>
                </div>
                <div className={styles.boxInput}>
                    <SimpleInput type={'text'} size={'sm'} readonly={true} value={object?.Email as string}/>
                </div>
            </div>
            <div className={styles.box}>
                <div className={styles.boxLabel}>
                    <span className={styles.label}>Формат:</span>
                </div>
                <div className={styles.boxInput}>
                    <SimpleInput type={'text'} size={'sm'} readonly={true} value={object?.PlaceType as string}/>
                </div>
            </div>
            <div className={styles.box}>
                <div className={classNames(styles.boxInput, styles.boxInputAddress)}>
                    <SimpleInput type={'text'} size={'sm'} readonly={true} value={object?.Address as string}/>
                </div>
            </div>
            <div className={styles.mapWrapper}>
                {renderMap}
            </div>
        </>
    );
};
