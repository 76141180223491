import React from 'react';
import {ReviewObj} from '../store/types';
import styles from '../style.module.scss';
import {Rating} from 'react-simple-star-rating';
import {Answers} from './Answers';

const image = require('../../../assets/images/user.png');

interface CardProps {
    item: ReviewObj;
}

export const Card = (
    {
        item
    }: CardProps
) => {

    const dateArr = item.dateCell.display.split(' ');
    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>
                <div className={styles.cardAvatar}>
                    <img src={image} alt={'user'}/>
                </div>
                <div className={styles.cardHeaderRow}>
                    <div>
                        <p className={styles.cardTitle}>
                            {item.author}
                        </p>
                        {item.authorPhone ? <div className={styles.cardPhone}>
                            <a href={`tel:+${item.authorPhone}`}>{item.authorPhone}</a>
                        </div> : null}
                        <Rating
                            readonly
                            size={15}
                            initialValue={item.ratingCell.sortValue}
                        />
                    </div>
                    <div className={styles.cardDateWrap}>
                        <span className={styles.cardDate}>{dateArr[0]}</span>
                        <span className={styles.cardDate}>{dateArr[1]}</span>
                    </div>
                </div>

            </div>
            <div className={styles.cardBody}>
                <p className={styles.cardName}>Объект: {item.objectName}</p>
                <p className={styles.cardName}>Источник: {item.provider}</p>

                <p className={styles.cardCommentText}>{item.comment !== '' ? item.comment : 'без комментария'}</p>
                {item.ratingContent
                    ? <div className={styles.cardTagList}>
                        <span className={styles.cardTag}># {item.ratingContent}</span>
                    </div>
                    : null}
            </div>

            <Answers Answer={item.Answer} provider={item.provider}/>
            <div className={styles.cardFooter}>
                <textarea placeholder={'написать ответ'} className={styles.cardAnswerInput}/>
                <button className={styles.cardAnswerButton}>
                    ответ
                </button>
            </div>
        </div>
    );
};
