import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {persistor, store} from './store';
import {pdfjs} from 'react-pdf';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </BrowserRouter>
    // </React.StrictMode>
);

reportWebVitals();

serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        const isUpdateConfirmed = window.confirm('Доступна новая версия. Хотите перезагрузить?');

        if (isUpdateConfirmed && registration.waiting) {
            registration.waiting.postMessage({type: 'SKIP_WAITING'});

            registration.waiting.addEventListener('statechange', (event: any) => {
                if (event.target?.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    },
});

