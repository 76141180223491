import {useLazyGetCostPriceQuery, useLazyGetRevenueQuery, useLazyGetWriteOffQuery} from '../store/report.api';
import {createParams} from '../Report';
import {useAppSelector} from '../../../hooks/app.hooks';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {extractValueFromString} from '../../../helpers';
import {typeFlow} from '../../../store/types';

export const useRequest = () => {
    const {search} = useLocation();
    const retortType = extractValueFromString(search, 'typeMob');

    const {filterHolding, date, FObjects} = useAppSelector(state => state.report);

    const [getRevenue, {data: revenueToday, isLoading: loaderMainTodayR}] = useLazyGetRevenueQuery();
    // const [getWriteOffQuery, {data: writeOffToday, isFetching: loaderMainTodayW}] = useLazyGetWriteOffQuery();
    // const [getCostPriceQuery, {data: costPriceToday, isFetching: loaderOtherTodayC}] = useLazyGetCostPriceQuery();

    const [getRevenueYesToday, {data: revenueYesToday, isLoading: loaderMainYesTodayR}] = useLazyGetRevenueQuery();

    const [getWriteOffQueryYesToday, {
        data: writeOffYesToday,
        isLoading: loaderMainYesTodayW
    }] = useLazyGetWriteOffQuery();
    const [getCostPriceQueryYesToday, {
        data: costPriceYesToday,
        isLoading: loaderOtherYesTodayC
    }] = useLazyGetCostPriceQuery();

    const [getRevenueWeek, {data: revenueWeek, isLoading: loaderMainWeekR}] = useLazyGetRevenueQuery();
    const [getWriteOffQueryWeek, {data: writeOffWeek, isLoading: loaderMainWeekW}] = useLazyGetWriteOffQuery();
    const [getCostPriceQueryWeek, {data: costPriceWeek, isLoading: loaderOtherWeekC}] = useLazyGetCostPriceQuery();

    const [getRevenueMonth, {data: revenueMonth, isLoading: loaderMonthTodayR}] = useLazyGetRevenueQuery();
    const [getWriteOffQueryMonth, {data: writeOffMonth, isLoading: loaderMonthTodayW}] = useLazyGetWriteOffQuery();
    const [getCostPriceQueryMonth, {data: costPriceMonth, isLoading: loaderOtherMonthC}] = useLazyGetCostPriceQuery();

    useEffect(() => {
        const today = createParams('today', filterHolding, date, FObjects);
        const yesterday = createParams('yesterday', filterHolding, date, FObjects);
        const week = createParams('week', filterHolding, date, FObjects);
        const month = createParams('month', filterHolding, date, FObjects);

        if (retortType === typeFlow.mainValuesRevenue) {
            getRevenue({params: today});
            getRevenueYesToday({params: yesterday});
            getRevenueWeek({params: week});
            getRevenueMonth({params: month});
            return;
        }

        if (retortType === typeFlow.mainValuesWriteOff) {
            // getWriteOffQuery({params: today}); // сейчас это лишний запрос, возможно в будущем будет актуальный
            getWriteOffQueryYesToday({params: yesterday});
            getWriteOffQueryWeek({params: week});
            getWriteOffQueryMonth({params: month});
            return;
        }

        if (retortType === typeFlow.mainValuesCostPrice) {
            //getCostPriceQuery({params: today}); // сейчас это лишний запрос, возможно в будущем будет актуальный
            getCostPriceQueryYesToday({params: yesterday});
            getCostPriceQueryWeek({params: week});
            getCostPriceQueryMonth({params: month});
            return;
        }

    }, [filterHolding, date]);


    return {
        revenueToday,
        // writeOffToday,
        revenueYesToday,
        writeOffYesToday,
        revenueWeek,
        writeOffWeek,
        revenueMonth,
        writeOffMonth,
        //costPriceToday,
        costPriceYesToday,
        costPriceWeek,
        costPriceMonth,
        loaderMainTodayR,
        //loaderMainTodayW,
        loaderMainYesTodayR,
        loaderMainYesTodayW,
        loaderMainWeekR,
        loaderMainWeekW,
        loaderMonthTodayR,
        loaderMonthTodayW,
        //loaderOtherTodayC,
        loaderOtherYesTodayC,
        loaderOtherWeekC,
        loaderOtherMonthC,
    };
};
