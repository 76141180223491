import {api} from '../../../store/api';
import {InfoCreateDoc, PhotoReportDocType, PhotoReportItem} from './types';
import {CONTENT_TYPE_URLENCODED} from '../../../utils/constants';
import {RootState} from '../../../store';

const photoReportApi = api.injectEndpoints({
    endpoints: build => ({
        getDocumentsTypes: build.query<PhotoReportDocType[], void>({
            query: () => ({
                url: '/documents/typesPhotoreports.php',
                method: 'GET'
            }),
            transformResponse: ({list}) => {
                return list;
            }
        }),
        getPhotoReports: build.query<PhotoReportItem[], { params: string }>({
            query: ({params}) => ({
                url: `/documents/photoreports.php?${params}`,
                method: 'GET'
            }),
            transformResponse: ({list}) => {
                return list;
            }
        }),
        photoReportFiles: build.mutation<any, { formData: FormData }>({
            query: ({formData}) => ({
                url: '/documents/fileAdd.php',
                method: 'POST',
                body: formData
            })
        }),
        actionsDoc: build.mutation<{ status: boolean, info: InfoCreateDoc }, { body: string }>({
            query: ({body}) => ({
                url: '/documents/r_Doc.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED
                }
            })
        }),
        getDocFiles: build.query<any, { ID_Tab: string }>({
            query: ({ID_Tab}) => ({
                url: `/documents/files.php?ID_Tab=${ID_Tab}`,
                method: 'GET'
            })
        }),
        getBlobFile: build.query<any, { path: string }>({
            queryFn: async ({path}, _queryApi) => {
                try {
                    const state = (_queryApi.getState() as RootState);
                    const id_con = state?.auth?.user?.id_con;
                    const response = await fetch(`${path}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'id_con': id_con
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error ${response.status}: ${response.statusText}`);
                    }

                    // Убедимся, что ответ в формате Blob
                    const blob = await response.blob();
                    const imageUrl = URL.createObjectURL(blob);
                    return {data: imageUrl}; // Возвращаем URL
                } catch (error: any) {
                    return {error: error.message || 'Unknown error'};
                }
            }
        })
    }), overrideExisting: true
});
//getFile/documentKRO?idDoc=__&fileName=__
export const {
    useGetDocumentsTypesQuery,
    useLazyGetPhotoReportsQuery,
    usePhotoReportFilesMutation,
    useActionsDocMutation,
    useLazyGetDocFilesQuery,
    useLazyGetBlobFileQuery
} = photoReportApi;
