import * as d3 from 'd3';
import {DynamicsDate} from '../store/types';

const russianLocale: any = {
    dateTime: '%A, %e %B %Y г. %X',
    date: '%d.%m.%Y',
    time: '%H:%M:%S',
    periods: ['AM', 'PM'],
    days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']
};

// Применение локализации
d3.timeFormatDefaultLocale(russianLocale);

export interface CreateD3ChartProps {
    datasets: DynamicsDate[] | DynamicsDate[][];
    isMultiple: boolean;
}

export const createD3Chart = ({datasets, isMultiple = false}: CreateD3ChartProps): void => {
    // Set up SVG canvas dimensions
    const width: number = window.innerWidth - 40;
    const height: number = 250;
    const margin = {top: 20, right: 20, bottom: 30, left: 50};
    const r: number = 13; // radius of the circles
    const colors = d3.scaleOrdinal(d3.schemeCategory10);

    const svg = d3.select('#dynamic')
        .append('svg')
        .attr('width', width)
        .attr('height', height);

    // Prepare data for multiple datasets or a single dataset
    const isMultipleDatasets = Array.isArray(datasets[0]);

    // Set up scales using combined or single dataset
    const allData = isMultipleDatasets ? (datasets as DynamicsDate[][]).flat() : datasets as DynamicsDate[];

    const x = d3.scaleTime()
        .domain(d3.extent(allData, d => new Date(d.timestamp * 1000)) as [Date, Date])
        .range([margin.left + r, width - margin.right - r]);

    const y = d3.scaleLinear()
        .domain([0, d3.max(allData, d => d.cnt) as number]).nice()
        .range([height - margin.bottom, margin.top]);

    // Create line generator
    const line = d3.line<DynamicsDate>()
        .x(d => x(new Date(d.timestamp * 1000)))
        .y(d => y(d.cnt))
        .curve(d3.curveLinear);

    // Add gridlines for Y axis
    const makeYGridlines = () => d3.axisLeft(y).ticks(5);

    svg.append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(${margin.left}, 0)`)
        .call(makeYGridlines()
            .tickSize(-width + margin.left + margin.right)
            .tickFormat('' as any));  // Отображение пустого текста для gridlines

    // Add gridlines for X axis
    const makeXGridlines = () => d3.axisBottom(x).ticks(7);

    svg.append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(0,${height - margin.bottom})`)
        .call(makeXGridlines()
            .tickSize(-height + margin.top + margin.bottom)
            .tickFormat('' as any));  // Отображение пустого текста для gridlines

    // Add X axis
    svg.append('g')
        .attr('transform', `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).ticks(7));

    // Add Y axis
    svg.append('g')
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(y));

    if (isMultiple) {
        // Multiple datasets case
        (datasets as DynamicsDate[][]).forEach((data, index) => {
            const color = colors(index.toString());

            // Draw the line for the current dataset
            svg.append('path')
                .datum(data)  // Use datum to bind array of data to line generator
                .attr('stroke', color)
                .attr('class', 'line')
                .attr('fill', 'none')
                .attr('d', line);  // Generate the line path

            // Draw circles for each data point in the current dataset
            svg.selectAll(`.dot-${index}`)
                .data(data)
                .enter().append('circle')
                .attr('class', `dot-${index}`)
                .attr('stroke', color)
                .attr('fill', color)
                .attr('cx', d => x(new Date(d.timestamp * 1000)))
                .attr('cy', d => y(d.cnt))
                .attr('r', r);

            // Add labels to each point in the current dataset
            svg.selectAll(`.text-${index}`)
                .data(data)
                .enter().append('text')
                .attr('class', `text-${index}`)
                .attr('x', d => x(new Date(d.timestamp * 1000)))
                .attr('y', d => y(d.cnt))
                .text(d => d.cnt.toString())
                .attr('fill', 'white')
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'middle');
        });
    } else {
        // Single dataset case
        svg.append('path')
            .datum(datasets as DynamicsDate[])  // Use datum to bind array of data to line generator
            .attr('stroke', 'blue')
            .attr('class', 'line')
            .attr('fill', 'none')
            .attr('d', line);  // Generate the line path

        // Draw circles for each data point
        svg.selectAll('.dot')
            .data(datasets as DynamicsDate[])
            .enter().append('circle')
            .attr('class', 'dot')
            .attr('stroke', 'blue')
            .attr('fill', 'blue')
            .attr('cx', d => x(new Date(d.timestamp * 1000)))
            .attr('cy', d => y(d.cnt))
            .attr('r', r);

        // Add labels to each point
        svg.selectAll('.text')
            .data(datasets as DynamicsDate[])
            .enter().append('text')
            .attr('class', 'text')
            .attr('x', d => x(new Date(d.timestamp * 1000)))
            .attr('y', d => y(d.cnt))
            .text(d => d.cnt.toString())
            .attr('fill', 'white')
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'middle');
    }
};
