import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useLazyGetByRangeQuery} from './store/monitoring.api';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {MonitoringDataCard, MonitoringFilter} from './components';
import {useMonitoringControl} from '../../hooks';
import AppLoader from '../../components/appLoader';

export const MonitoringByRange = () => {
    const navigate = useNavigate();

    const [getByRange, {data, isLoading}] = useLazyGetByRangeQuery();
    const {applyFilter, clearFilter, filter} = useMonitoringControl({
        onFetch: ({params}) => getByRange({params})
    });

    const handleClick = (key: string) => {

        const names: { [key: string]: string } = {
            paySum: 'Выручка за период по объектам',
            at: 'Ср чек за период по объектам',
            trx: 'Кол-во чеков за период по объектам',
            adt: 'Чеков в день/тчк'
        };
        const startDate = filter.find(i => i.type === 'Start')?.value;
        const finishDate = filter.find(i => i.type === 'Finish')?.value;
        navigate('/view-table', {
            state: {
                path: `/pdfReports/generateRevenue.php?answerType=2&holding=dp&periodType=period&startDate=${startDate}&finishDate=${finishDate}`,
                useField: key,
                tableName: names[key] ?? undefined
            }
        });
    };
    return (
        <MainLayout>
            <Header breadCrumbs={'Мониторинг - Выручка за период'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={false}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <div className={styles.list}>
                            <MonitoringDataCard
                                column={2}
                                num={data?.paySum}
                                text={'Выручка'}
                                handleClick={() => handleClick('paySum')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.trx}
                                text={'Kол-во чеков (TRX)'}
                                handleClick={() => handleClick('trx')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.at}
                                text={'Средний чек(AT)'}
                                handleClick={() => handleClick('at')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.adt}
                                text={'Чеков/день/тчк (ADT)'}
                                handleClick={() => handleClick('adt')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.upt}
                                text={'Товары/чек (UPT)'}
                                handleClick={() => handleClick('upt')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.uptm}
                                text={'Тов+модиф/чек (UPTM)'}
                                handleClick={() => handleClick('uptm')}
                            />
                        </div>
                    )
            }

            <Footer/>
        </MainLayout>
    );
};
