import React, {useEffect, useState} from 'react';

const ErrorBoundary = ({children}: { children: any }) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const handleError = (event: any) => {
            setHasError(true);
        };

        window.addEventListener('error', handleError);
        window.addEventListener('unhandledrejection', handleError);

        return () => {
            window.removeEventListener('error', handleError);
            window.removeEventListener('unhandledrejection', handleError);
        };
    }, []);

    if (hasError) {
        return (

            <div className={'container'}>
                <h3>Что-то пошло не так.</h3>
                <p>Произошла ошибка. Пожалуйста, перезагрузите страницу или переустановите приложение.</p>
                <button onClick={() => window.location.href = '/'}>Перезагрузить</button>
            </div>

        );
    }

    return children;
};

export default ErrorBoundary;
