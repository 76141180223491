import React, {useEffect} from 'react';
import {AuthScreen} from './Auth.screen';
import {MainLayout} from '../../layouts';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {toast} from 'react-toastify';
import {clearAuthMessage} from './store/auth.slice';

export const AuthContainer = () => {
    const dispatch = useAppDispatch();
    const {authStatusMessage} = useAppSelector(state => state.auth);

    useEffect(() => {
        if (authStatusMessage) {
            toast.info(authStatusMessage, {
                autoClose: false,
                onClose: () => dispatch(clearAuthMessage())
            });
        }
    }, [authStatusMessage]);
    return (
        <MainLayout>
            <AuthScreen/>
        </MainLayout>
    );
};
