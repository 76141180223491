import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import {IFinancialFilter} from '../../../components/financialFilter/store/type';
import styles from '../style.module.scss';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import {rangePresets} from '../../../components/taskFilter/TaskFilter';
import {DatePicker, Select} from 'antd';
import {UnmountClosed} from 'react-collapse';
import {SwitcherTab} from '../../../components/switcherTab';
import {useGetFilterObjMutation} from '../../../components/financialFilter/store/financial.filter.api';
import {fromJsonToUrlencoded} from '../../../helpers';
import classNames from 'classnames';
import {dateKeys, setReviewsFilter} from '../store/review.slice';

const {RangePicker} = DatePicker;

interface FilterProps {
    clearFilter: () => void;
    applyFilter: (newKeys?: any[]) => void;
}

export const Filter = (
    {
        applyFilter,
        clearFilter
    }: FilterProps
) => {
    const dispatch = useAppDispatch();

    const {filter} = useAppSelector(state => state.reviews);

    const [getFilter, {data: objects}] = useGetFilterObjMutation({fixedCacheKey: 'objectFilter'});

    const [isCollapsed, setCollapse] = useState<boolean>(false);
    const [storeIndex, setStoreIndex] = useState<number[]>([]);
    const [FObjects, setSelectedObj] = useState<string[]>([]);

    useEffect(() => {
        getFilter({body: fromJsonToUrlencoded({data: {ShowComp: 'NULL', flagSHID: false}})});
    }, []);

    const handleChangeDate = (dateStrings: [string, string]) => {
        const formattedDates = dateStrings.map(date =>
            (dayjs(date, 'D MMM, YYYY').format('YYYY-MM-DD'))
        );

        const newKeys = filter.map(key => {
            if (key.type === 'Start') return {...key, value: formattedDates[0]};
            if (key.type === 'Finish') return {...key, value: formattedDates[1]};
            return key;
        });

        dispatch(setReviewsFilter(newKeys));
        applyFilter(newKeys);
    };

    const handleChangeDate2 = (dateStrings: [string, string]) => {
        const formattedDates = dateStrings.map(date =>
            (dayjs(date, 'D MMM, YYYY').format('YYYY-MM-DD'))
        );
        const newKeys = filter.map(key => {
            if (key.type === 'Start2') return {...key, value: formattedDates[0]};
            if (key.type === 'Finish2') return {...key, value: formattedDates[1]};
            return key;
        });
        dispatch(setReviewsFilter(newKeys));
    };

    useEffect(() => {
        const items = ['Corp', 'Fr'].filter((i, idx) => storeIndex.includes(idx));
        if (filter.some(key => key.type === 'FParentCodes')) {
            const newKeys = filter.map(item => item.type === 'FParentCodes' ? {...item, value: items} : item);
            dispatch(setReviewsFilter(newKeys));
        } else {
            items.length > 0 &&
            dispatch(setReviewsFilter([...filter, {type: 'FParentCodes', value: items}]));
        }
    }, [storeIndex]);


    const handleApply = () => {
        applyFilter();
        setCollapse(false);
    };

    const handleSelectObj = (value: string[]) => {
        if (filter.some(key => key.type === 'FObjects')) {
            const newKeys = filter.map(item => item.type === 'FObjects' ? {...item, value} : item);
            dispatch(setReviewsFilter(newKeys));
        } else {
            dispatch(setReviewsFilter([...filter, {type: 'FObjects', value}]));
        }
        setSelectedObj(value);
    };

    const clear = () => {
        dispatch(setReviewsFilter(dateKeys));
        setStoreIndex([]);
        setCollapse(false);
        setSelectedObj([]);
        clearFilter();
    };

    const dateStart = filter.find(k => k.type === 'Start') as IFinancialFilter;
    const dateFinish = filter.find(k => k.type === 'Finish') as IFinancialFilter;
    const dateStart2 = filter.find(k => k.type === 'Start2') as IFinancialFilter;
    const dateFinish2 = filter.find(k => k.type === 'Finish2') as IFinancialFilter;

    return (
        <div>
            <div className={styles.dataPickerWrapper}>
                <div className={styles.dataPickerWrapperData}>
                    <RangePicker
                        popupClassName="custom-range-picker-dropdown"
                        className={styles.inputRange}
                        locale={ru_RU}
                        format="D MMM, YYYY"
                        presets={rangePresets}
                        value={[dayjs(dateStart.value as string, 'YYYY.MM.DD'), dayjs(dateFinish.value as string, 'YYYY.MM.DD')]}
                        onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                        separator="-"
                        allowClear={false}
                    />
                </div>
                <button
                    className={styles.dataPickerWrapperButton}
                    onClick={() => setCollapse(!isCollapsed)}>
                    {isCollapsed ? 'Скрыть' : 'Фильтр'}
                </button>
            </div>

            <UnmountClosed isOpened={isCollapsed}>
                <div className={styles.filterCollapse}>
                    <div className={styles.filterCollapseRow}>
                        <p className={styles.selectDateLabel}>Интервал 2</p>
                        <RangePicker
                            popupClassName="custom-range-picker-dropdown"
                            className={classNames(styles.inputRange, styles.inputRangeW70)}
                            locale={ru_RU}
                            format="D MMM, YYYY"
                            presets={rangePresets}
                            value={[dayjs(dateStart2.value as string, 'YYYY.MM.DD'), dayjs(dateFinish2.value as string, 'YYYY.MM.DD')]}
                            onChange={(dates, dateStrings) => handleChangeDate2(dateStrings)}
                            separator="-"
                            allowClear={false}
                        />
                    </div>
                    <div className={styles.filterCollapseRow}>
                        <p className={styles.selectDateLabel}>Объекты</p>
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="Выбрать объект"
                            className={classNames(styles.inputRange, styles.inputRangeW70)}
                            defaultValue={FObjects as string[]}
                            onChange={(value) => handleSelectObj(value)}
                            options={objects?.listObject ?? []}

                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                        />
                    </div>
                    <div className={styles.inputWrap}>
                        <SwitcherTab
                            pageType={'report'}
                            isActive={storeIndex}
                            buttons={['Корп', 'Фр']}
                            isMultiple
                            onClick={(index) => {
                                if (storeIndex.includes(index)) {
                                    setStoreIndex(prevState => prevState.filter(i => i !== index));
                                } else {
                                    setStoreIndex([...storeIndex, index]);
                                }
                                // handleSwitchStore(index);
                            }}
                        />
                    </div>
                    <div className={styles.filterButtons}>
                        <button
                            className={styles.filterButtonsButton}
                            onClick={clear}
                        >Очистить фильтр
                        </button>
                        <button
                            className={styles.filterButtonsButton}
                            onClick={handleApply}
                        >Применить фильтр
                        </button>
                    </div>
                </div>
            </UnmountClosed>
        </div>
    );
};
