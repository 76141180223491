import React from 'react';
import {ImageLayout, MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {setFilter} from './store/monitoring.slice';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import dayjs from 'dayjs';
import {IFilter} from '../../components/taskFilter/store/type';
import {getObjectByIdForm} from '../../helpers';
import {ListMenu} from '../home/store/types';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import SimpleButton from '../../components/simpleButton';
import {typeFlow} from '../../store/types';
import {SplitStringWithLineBreak} from './components';
import {themColors} from '../../hooks/useChangeTheme';
import {ChartUser} from '../../assets/customSvg';


const today = dayjs();

export const todayDateKeys: IFilter[] = [
    {type: 'Start', value: today.format('YYYY-MM-DD')},
    {type: 'Finish', value: today.format('YYYY-MM-DD')},
];

const todayTypes = ['MonitorToDay', 'MonitorMap', 'MonitorLine', 'MonitorLoad'];
const byRangeTypes = ['MonitorPer', 'MonitorTime', 'MonitorIst', 'MonitorKur'];
const putStopTypes = ['MonitorStopOb'];

const Buttons = ({item, handlePress}: { item: ListMenu, handlePress: (item: ListMenu) => void }) => {
    const {theme} = useAppSelector(state => state.app);
    return (
        <SimpleButton
            key={item.idForm}
            column={2}
            handler={() => handlePress(item)}
        >
            <div className={styles.listItem}>
                {
                    item.icon.type === 'iconsvg'
                        ? <ChartUser color={themColors[theme].color} width={34} height={34}
                                     fileName={item.icon.source as string}/>
                        : (
                            <ImageLayout
                                src={item.icon.source}
                                imageWidth={32}
                                imageHeight={32}
                                containerWidth={32}
                                containerHeight={32}
                            />
                        )
                }
                <SplitStringWithLineBreak className={styles.listText} text={item.name}/>
            </div>
        </SimpleButton>
    );
};

export const Monitoring = () => {
    const {idForm} = useParams<{ idForm: string }>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {data} = useGetAllQuestionsQuery();
    const viewButtons = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));

    const firstButton = viewButtons?.children.filter(i => todayTypes.includes(i.typeMob));
    const secondButton = viewButtons?.children.filter(i => byRangeTypes.includes(i.typeMob));
    const putStop = viewButtons?.children.filter(i => putStopTypes.includes(i.typeMob));

    const handlePress = (item: ListMenu) => {
        switch (item.typeMob) {
            case typeFlow.MonitorToDay:
                dispatch(setFilter(todayDateKeys));
                return navigate(`/monitoring-main/${item.idForm}`);
            case typeFlow.MonitorLoad:
                dispatch(setFilter(todayDateKeys));
                return navigate(`/monitoring-load/${item.idForm}`);
            case typeFlow.MonitorPer:
                return navigate(`/monitoring-range/${item.idForm}`);
            case typeFlow.MonitorLine:
                return navigate(`/monitoring-select/${item.idForm}`);
            case typeFlow.MonitorIst:
                return navigate(`/monitoring-source/${item.idForm}`);
            case typeFlow.MonitorKur:
                return navigate(`/monitoring-courier/${item.idForm}`);
            case typeFlow.MonitorTime:
                return navigate(`/monitoring-step/${item.idForm}`);
            case typeFlow.MonitorMap:
                return navigate(`/monitoring-map/${item.idForm}`);
            case typeFlow.MonitorStopOb:
                return navigate(`/monitoring-stop/${item.idForm}`);
            default:
                return;
        }
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Мониторинг'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.wrapper}>
                <p className={styles.title}>Сегодня:</p>
            </div>

            <div className={styles.list}>
                {firstButton?.map(item => <Buttons key={item.idForm} item={item} handlePress={handlePress}/>)}
            </div>
            <div className={styles.listBox}>
                <div className={styles.separator}/>
                <p className={styles.title}>За период:</p>
            </div>

            <div className={styles.list}>
                {secondButton?.map(item => <Buttons key={item.idForm} item={item} handlePress={handlePress}/>)}
            </div>
            <div className={styles.listBox}>
                <div className={styles.separator}/>
                <p className={styles.title}>Постановки в стоп:</p>
            </div>
            <div className={styles.list}>
                {putStop?.map(item => <Buttons key={item.idForm} item={item} handlePress={handlePress}/>)}
            </div>

            <Footer/>
        </MainLayout>
    );
};
