import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import styles from './style.module.scss';
import {mainColor} from '../../utils/constants';
import {Button} from '../../fields/button';
import {ChartControls} from './components';
import {useLazyGetAllReportQuery} from './store/report.api';
import AppLoader from '../../components/appLoader';
import {FilterOpt, ReportData} from './store/types';
import {CombineCharts} from './components/CombineCharts';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {SwitcherTab} from '../../components/switcherTab';
import {setSortType} from './store/report.slice';
import Footer from '../../components/footer';
import {isToday} from '../../helpers';
import {dateRange} from './Report';
import {resetGlobalAbortController} from '../../store/abortController';


const buttonsTypes = ['Выручка', 'Ср.Чек', 'Кол.Чеков'];

function createParams(
    group: string,
    Start: string,
    Finish: string,
    FObjects: string[]
): string {
    let params = `group=${group}&Finish=${Finish}&Start=${Start}`;
    if (FObjects.length > 0) {
        params = `${params}&FObjects=${FObjects.join(',')}`;
    }

    return params;
}

export const ViewReport = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [dataChart, setDataChart] = useState<ReportData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const {date, group, sortType, FObjects, reportSwitchIndex} = useAppSelector(state => state.report);
    const [getReport, {data}] = useLazyGetAllReportQuery();


    useEffect(() => {
        const Start = date.length > 0 ? date[0] : dateRange[reportSwitchIndex][0];
        const Finish = date.length > 0 ? date[1] : dateRange[reportSwitchIndex][1];
        const params = createParams(group, Start, Finish, FObjects);
        getReport({params}).unwrap().finally(() => setLoading(false));
    }, [group, date]);

    useEffect(() => {
        if (data) {
            setDataChart(data.data);
            setLoading(false);
        }
    }, [data]);


    const handleClickByLegend = ({type, cat, value}: { type: string, cat: string, value: string }) => {
        const items = data?.data as ReportData[];

        setLoading(true);

        if (value === '0') {
            const newItems: ReportData[] = [];
            for (const item of items) {
                if (item[type as keyof ReportData] === cat) {
                    newItems.push(item);
                }
            }

            setDataChart([...dataChart, ...newItems]);
        } else {
            const newItems: ReportData[] = [];

            for (const item of dataChart) {
                if (item[type as keyof ReportData] !== cat) {
                    newItems.push(item);
                }
            }
            setDataChart(newItems);
        }

        setLoading(false);
    };


    const handleSwitchStore = (index: number) => {
        dispatch(setSortType(index));
    };

    const goBask = () => {
        resetGlobalAbortController();
        navigate(-1);
    };

    if (date.length > 0 ? isToday(date[0]) : isToday(dateRange[reportSwitchIndex][0])) {
        return (
            <MainLayout>
                <Header breadCrumbs={'Отчеты'}/>
                <BackButton text={'Назад в главное меню'} onClick={goBask}/>
                <div className={'container'}>
                    <div className={styles.empty}>
                        <p className={styles.emptyText}>За этот период времени данных нет.</p>
                    </div>
                </div>
            </MainLayout>
        );
    }

    return (
        <MainLayout>
            <Header breadCrumbs={'Отчеты дэшборд выручка'}/>
            <BackButton text={'Назад в главное меню'} onClick={goBask}/>

            <div className={'container'}>
                {loading
                    ? <AppLoader isFull={false}/>
                    : (<>
                            <div className={styles.switcher}>
                                <div className={styles.switcherButtons}>
                                    <SwitcherTab
                                        pageType={'report'}
                                        isActive={[sortType]}
                                        buttons={buttonsTypes}
                                        isMultiple
                                        onClick={(index) => handleSwitchStore(index)}
                                    />
                                </div>
                                <div className={styles.switcherFilter}>
                                    <Button
                                        text={'Фильтр'}
                                        textStyleType={'regular'}
                                        style={{
                                            height: '41px',
                                            border: `1px solid ${mainColor}`,
                                            borderRadius: '12px'
                                        }}
                                        onClick={() => setOpen(!isOpen)}/>
                                </div>

                            </div>
                            <ChartControls
                                isOpen={isOpen}
                            />

                            <CombineCharts
                                dataChart={dataChart}
                                filters={data?.filters as FilterOpt}
                                handleClickByLegend={handleClickByLegend}
                            />
                        </>
                    )}
            </div>
            <Footer/>
        </MainLayout>
    );
};
