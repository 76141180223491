import {createSlice} from '@reduxjs/toolkit';
import {authApi} from './auth.api';
import {User} from './types';

const defaultUser: User = {
    token: '',
    login: '',
    name: '',
    id_con: '',
    refreshToken: '',
    id: 0,
    email: '',
    notificationToken: undefined
};

export interface AuthSlice {
    isAuth: boolean;
    authStatusMessage: string | null;
    user: User;
};

const initialState: AuthSlice = {
    isAuth: false,
    authStatusMessage: null,
    user: defaultUser
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.isAuth = false;
            state.user = defaultUser;
        },
        clearAuthMessage: (state) => {
            state.authStatusMessage = null;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, {payload}) => {
                if (!payload.status) {
                    state.isAuth = false;
                    state.authStatusMessage = payload.message as string;
                    state.user = defaultUser;
                } else {
                    state.user = payload.user;
                    state.isAuth = true;
                }
            }
        );
        builder.addMatcher(
            authApi.endpoints.checkSession.matchFulfilled,
            (state, {payload}) => {
                if (!payload.status) {
                    state.isAuth = false;
                    state.authStatusMessage = 'Нет доступа';
                    state.user = defaultUser;
                }
            }
        );
    }
});

export const {logout, clearAuthMessage} = authSlice.actions;
export default authSlice.reducer;
